import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import WarningIcon from '@material-ui/icons/Warning';
import NewsEventsForm from '../../../../components/NewsAndEvents/NewsEventsForm';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import { IRegisterEvent } from '../../../../interfaces/IRegisterEvent';
import { getEventsNews } from '../../../../services/NewsEventsService';
import PreviewNews from '../../../../components/NewsAndEvents/PreviewNews';
import { containsAny, sortByKey } from '../../../../helpers/sharedFunctions';
import { getCATEGORY } from '../../../../services/ParameterService';
import ModalOffersAction from '../../../../components/LaboralOffers/ModalOffersAction';
import UserContext from '../../../../utils/UserContext';
import { permissionAdminCreateNewEvent, permissionAdminModifyNewEvent } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';

import './NewEventFormPage.scss';

const initialFormValues ={
    type: "",
    category: "",
    fileType: "",
    name: "",
    fileError: "",
    description: "",
    shortDescription: "",
    place: "",
    dateStart: "",
    timeStart: "",
    dateFinish: "",
    timeFinish: "",
    population: "",
}

const NewEventFormPage: React.FC = () => {
    let params: any = useParams();
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [action, setAction] = useState<string>();
    const [formValues, setFormValues] = useState(new IRegisterEvent({}));
    const [formErrors, setFormErrors] = useState(initialFormValues);
    const [newDetail, setNewDetail] = useState<IRegisterEvent>(new IRegisterEvent({}));
    const [preview, setPreview] = useState(false);
    const [categoryArray, setCategoryArray] = useState<Array<IGeneralParameter>>([]);
    const [detailSelected, setDetailSelected] = useState<IRegisterEvent>(new IRegisterEvent({}));
    const [infoUpdated, setInfoUpdated] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [cancelUrl, setCancelUrl] = useState<boolean>(false);
    const [alreadySaved, setAlreadySaved] = useState<boolean>(false);
    const [formChanged, setFormChanged]= useState<boolean>(false);

    useEffect(()=>{
        window.scrollTo(0, 0);
        const urlPath = window.location.href;
        if(urlPath.includes('crear')){
            setAction("Create")
        } else if (urlPath.includes('editar')){
            setAction("Edit")
        }
        const getInitialdata = async()=>{
            let responseCategory:Array<IGeneralParameter> = await getCATEGORY();
            responseCategory = sortByKey(responseCategory, 'name');
            setCategoryArray(responseCategory);
        }
        getInitialdata();
    },[])


    useEffect(()=>{
        const getDetailInfo = async()=>{
            if(params.id){
                const responseDetail: Response = await trackPromise(getEventsNews({id:parseInt(params.id)}));
                if(responseDetail.status === 200){
                    const resultDetail = (await responseDetail.json()).publishings[0];
                    const newObject: IRegisterEvent = new IRegisterEvent(resultDetail);
                    setNewDetail(newObject);
                }
            }
        }
        getDetailInfo();
    },[params.id])

    const previewButton = (e:any, selected: IRegisterEvent) => {
        setPreview(true);
        setDetailSelected(selected);
    }

    const handleClickBack = () => {
        setPreview(false);
    };

    const cancelAction = ()=>{
        if(alreadySaved){
            setCancelUrl(true);
        }else {
            if(formChanged){
                setOpenCancelModal(true);
            } else {
                setCancelUrl(true);
            }
        }
    }

    const closeCancelModal = () =>{
        setOpenCancelModal(false);
    }


    return(
        <>
            {preview ?
                <>
                    <Grid container item xs={12} className="back-offers-list-section">
                        <Grid container className="main-container">
                            <Grid container item xs={12} className="back-offers-btn-container">
                            <Button
                                variant="contained"
                                className="step-back-offers"
                                startIcon={<NavigateBeforeIcon />}
                                onClick={handleClickBack}
                            >
                                <Typography variant="h6" className="regular-serif">
                                    Regresar al formulario
                                </Typography>
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <PreviewNews
                        detailSelected={detailSelected}
                        previewPage="creacion"
                        handleClickBack={handleClickBack}
                    />
                </>
            :
                <>
                {!cancelUrl ?
                    <>
                        <Grid container item xs={12} className="back-offers-list-section">
                            <ButtonBackListOffers url="/admin/dashboard/noticias" text="Regresar al listado de recursos"/>
                        </Grid>
                        <Grid container className="main-container">
                            <Grid container item xs={12} className="events-container">
                                <Grid container item xs={12} md={4} lg={4}>
                                    <div className="title-section-line">
                                        <DescriptionIcon className="group-icon"/>
                                        <Typography variant="h2" className="event-title" >
                                            {action !== "Edit" ? "Nuevo recurso" : "Editar recurso"}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid container item xs={12} md={8} lg={8} className="line" />
                                <Grid container >
                                    {
                                        action === "Edit" && newDetail.type.length>0 ?
                                        <>
                                        {containsAny(permissionAdminModifyNewEvent,userContext.userPermissions) ?
                                            <NewsEventsForm
                                                action={action}
                                                infoUpdated={infoUpdated}
                                                setInfoUpdated={setInfoUpdated}
                                                formValues={formValues}
                                                setFormValues={setFormValues}
                                                formErrors={formErrors}
                                                setFormErrors={setFormErrors}
                                                newDetail={newDetail}
                                                previewButton={previewButton}
                                                categoryArray={categoryArray}
                                                cancelAction={cancelAction}
                                                setAlreadySaved={setAlreadySaved}
                                                setFormChanged={setFormChanged}
                                            />
                                        :
                                            <NotAuthorized/>
                                        }
                                        </>
                                    :
                                        <>
                                        {containsAny(permissionAdminCreateNewEvent,userContext.userPermissions) ?
                                            <NewsEventsForm
                                                action="Create"
                                                infoUpdated={infoUpdated}
                                                setInfoUpdated={setInfoUpdated}
                                                formValues={formValues}
                                                setFormValues={setFormValues}
                                                formErrors={formErrors}
                                                setFormErrors={setFormErrors}
                                                previewButton={previewButton}
                                                categoryArray={categoryArray}
                                                cancelAction={cancelAction}
                                                setAlreadySaved={setAlreadySaved}
                                                setFormChanged={setFormChanged}
                                            />
                                        :
                                            <NotAuthorized/>
                                        }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <ModalOffersAction
                            open={openCancelModal}
                            handleClose={closeCancelModal}
                            iconModal={<WarningIcon className="big-cancel-icon"/>}
                            title="Usted está a punto de salir sin guardar"
                            headerdescription=""
                            description="¿desea continuar?"
                            backBtnText="Regresar"
                            colorBtnBack= ""
                            continueBtnText="Continuar"
                            colorBtnContinue="#14A5DC"
                            linkContinue="/admin/dashboard/noticias"
                            valid={true}
                        />
                    </>
                :
                    <Redirect to="/admin/dashboard/noticias"/>
                }
                </>
            }
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default NewEventFormPage