import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { IFilterOfferForm } from '../../../../interfaces/IFilterOfferForm';
import AnnouncementFilters from '../../../../components/LaboralOffers/AnnouncementFilters';
import { ChipData } from '../../../../interfaces/ChipData';
import { IGetOffer } from '../../../../interfaces/IGetOffer';
import ShowFilters from '../../../../components/ManagementState/ShowFilters';
import OffersTable from '../../../../components/LaboralOffers/OffersTable/index';
import { getlistOffers } from '../../../../services/LaboralOfferService';
import { IAnnouncement } from '../../../../interfaces/IAnnouncement';
import { OfferStateObject } from '../../../../interfaces/OfferStateObject';
import { OfferStateTosendObj } from '../CompanyInfoView';
import OffersChangeAction from '../../../../components/LaboralOffers/OffersChangeAction';
import OfferDetailAdmin from '../../../../components/LaboralOffers/OfferDetailAdmin';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { getCITY, getCOUNTRIES_CITIES, getDEPARTAMENT } from '../../../../services/ParameterService';
import { containsAny, sortByKey } from '../../../../helpers/sharedFunctions';
import Validations from '../../../../helpers/Validations';
import { IFilterOfferLimit } from '../../../../interfaces/IFilterOfferLimit';
import UserContext from '../../../../utils/UserContext';
import { permissionAdminSeeNewOffers } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import { IListParametersInfo } from '../../../../interfaces/IListParametersInfo';


const filterOffersValidation = {
  country: {
    validate: "select",
    required: false
  },
  department: {
    validate: "select",
    required: false
  },
  city: {
    validate: "select",
    required: false
  },
  name: {
    error: "",
    validate: "textNumber",
    required: false
  },
  nit: {
    error: "",
    validate: "number",
    minLength: 7,
    maxLength: 15,
    required: false
  },
}

const initialOffersFilterErrors = {
  department: "",
  city: "",
  name: "",
  nit: ""
}

const fieldSearchValidation = {
  searched: {
    error: "",
    validate: "textNumber",
    required: false
  }
};

const initialErrorsSearch = {
  searched: ''
}

const OffersAdminView: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<IFilterOfferForm>(new IFilterOfferForm());
  const [formErrors, setFormErrors] = useState(initialOffersFilterErrors);
  const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
  const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
  const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [offersList, setOffersList] = useState<Array<IGetOffer>>([]);
  const [offersFiltered, setOffersFiltered] = useState<Array<IGetOffer>>([]);
  const [initPage, setInitPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [offersStateToSend, setOffersStateToSend] = useState<OfferStateTosendObj>(new OfferStateTosendObj());
  const [offersToChangeTable, setOffersToChangeTable] = useState<Array<IAnnouncement>>([]);
  const [updateReady, setUpdateReady] = useState<string>('');
  const [offerSelected, setOfferSelected] = useState<IGetOffer>(new IGetOffer());
  const [departmentSelected, setDepartmentSelected] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [currrentPage, setCurrentPage] = useState<number>(0);
  const [searched, setSearched] = useState('');
  const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
  const [searchActive, setSearchActive] = useState(false);
  const limitNumber = 100;

  const isValid =
    !formErrors.name &&
    !formErrors.nit

  useEffect(() => {
    window.scrollTo(0, 0);
    const getInitialData = async () => {
      const objectToSend: IFilterOfferLimit = tranformDataOfferLimit(formValues, limitNumber === rowsPerPage ? limitNumber + 25 : limitNumber, 0);
      const resOffers = await getlistOffers(objectToSend);
      if (resOffers.status === 200) {
        let resultOffers = (await resOffers.json()).jobOffers;
        setOffersList(resultOffers);
        setOffersFiltered(resultOffers);
      }
      // data countries-cities
      let dataCountryCities: Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
      dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
      setCountryCitiesArray(dataCountryCities);
      // type department field
      let dataDepartment: Array<IGeneralParameter> = await getDEPARTAMENT();
      dataDepartment = sortByKey(dataDepartment, 'name');
      setDepartmentArray(dataDepartment || []);
      // type city array
      const dataCity: Array<IGeneralParameter> = await getCITY();
      setCityArray(dataCity || []);
      setInitLoad(false);
    }
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formValues.country === 0) {
      setFormValues(prev => ({
        ...prev,
        department: 0,
        city: 0
      }));
      setCities([]);
    } else {
      if (formValues.country === 170) {
        setCities([]);
      } else {
        setFormValues(prev => ({
          ...prev,
          department: 0,
          city: 0
        }));
        const countryObjSelected = countryCitiesArray.find(country => country.id === formValues.country);
        if (countryObjSelected) {
          setCities(countryObjSelected.childs);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.country])

  useEffect(() => {
    setCities([]);
    setFormValues(prev => ({
      ...prev,
      city: 0
    }));
    if (formValues.department > 0) {
      let citiesDep: Array<IGeneralParameter> = cityArray.filter(city => city.parentId === formValues.department);
      citiesDep = sortByKey(citiesDep, 'name');
      if (citiesDep.length > 0) {
        setCities(citiesDep)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.department])

  useEffect(() => {
    const checkForNewData = async () => {
      if (offersList.length > 0) {
        const totalRows = offersList.length;
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        let lastPage = false;
        if (totalPages - currrentPage === 0) {
          lastPage = true;
        } else {
          lastPage = false;
        }
        const objectToSend: IFilterOfferLimit = tranformDataOfferLimit(formValues, limitNumber === rowsPerPage ? limitNumber + 25 : limitNumber, totalRows);
        if (lastPage) {
          updateDataOffers(objectToSend, offersList);
        }
      }
    }
    checkForNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currrentPage, rowsPerPage])

  const tranformDataOfferLimit = (values: IFilterOfferForm, limit: number, offset: number) => {
    const temporaryObject: IFilterOfferLimit = new IFilterOfferLimit();
    if (values.department > 0) {
      temporaryObject.department = values.department;
    } else { delete temporaryObject.department }
    if (values.city > 0) {
      temporaryObject.city = values.city;
    } else { delete temporaryObject.city }
    if (values.name !== "") {
      temporaryObject.name = values.name;
    } else { delete temporaryObject.name }
    if (values.nit === null || values.nit === "") {
      delete temporaryObject.company
    } else { temporaryObject.company = values.nit; }
    temporaryObject.limit = limit;
    temporaryObject.offset = offset;
    temporaryObject.state = ["En definición"];
    return temporaryObject;
  }

  const updateDataOffers = async (objectToSend: IFilterOfferLimit, currentData: Array<any>) => {
    const resOffers = await trackPromise(getlistOffers(objectToSend));
    if (resOffers.status === 200) {
      const newData = (await resOffers.json()).jobOffers;
      if (newData.length > 0) {
        setOffersList([...currentData, ...newData]);
        setOffersFiltered([...currentData, ...newData]);
      }
      if (objectToSend.offset === 0 && newData.length === 0) {
        setOffersList([]);
        setOffersFiltered([]);
      }
      setSearched('');
      setSearchActive(false);
    }
  }

  const changeFilterForm = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error: string = Validations(name, value, filterOffersValidation) || "";
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  }

  useEffect(() => {
    setChips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, isValid])

  const setChips = () => {
    let tempArray: Array<ChipData> = []
    let index = 0;
    const fieldNames = [
      { key: 'name', description: 'Razón social' },
      { key: 'company', description: 'Nit de la empresa' },
      { key: 'department', description: 'Departamento' },
      { key: 'city', description: 'Municipio' }
    ];
    for (const [key, value] of Object.entries(formValues)) {
      if (value !== 0 && value !== '' && value !== null) {
        if ((key === "department" || key === "city" || key === "name" || key === "nit")) {
          let obj;
          const fieldObj = fieldNames.find(field => field.key === key);
          if (key === 'department') {
            obj = departmentArray.find(dep => dep.parameter_code === value);
          } else if (key === 'city') {
            obj = cityArray.find(city => city.parameter_code === value);
          }
          if (obj) {
            tempArray.push({ key: index, name: key, label: fieldObj?.description + ': ' + obj.name })
          } else {
            tempArray.push({ key: index, name: key, label: fieldObj?.description + ': ' + value })
          }
          index++;
        }
      }
    }
    setChipData(tempArray);
  }

  const handleDeleteChip = (chipToDelete: ChipData) => () => {
    const name = chipToDelete.name;
    setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
    if (name === 'department' || name === 'city') {
      setFormValues(prev => ({
        ...prev,
        [chipToDelete.name]: 0
      }));
    } else {
      setFormValues(prev => ({
        ...prev,
        [chipToDelete.name]: ''
      }));
    }
  }

  const clickApplyFilters = (value: any, action: string) => {
    if (action === 'apply') {
      const objectToSend: IFilterOfferLimit = tranformDataOfferLimit(formValues, limitNumber === rowsPerPage ? limitNumber + 25 : limitNumber, 0);
      updateDataOffers(objectToSend, []);
      setInitPage(0);
    } else if (action === 'clear') {
      setChipData([]);
      setFormErrors(initialOffersFilterErrors);
      const objectToSend = tranformDataOfferLimit(new IFilterOfferForm(), limitNumber === rowsPerPage ? limitNumber + 25 : limitNumber, 0);
      updateDataOffers(objectToSend, []);
      setInitPage(0);
      setFormValues(new IFilterOfferForm());
    }
  }

  const onChanhePageSize = (e: any) => {
    setRowsPerPage(e.target.value);
    setInitPage(0);
  }

  const handleRowOffer = (action: string, offerSelected: IAnnouncement) => {
    const offerId = offerSelected.id;
    const stateSelected = action;
    if (offerId && stateSelected.length > 0) {
      let offersObject: Array<IAnnouncement> = [offerSelected];
      let offerStructure: Array<OfferStateObject> = [];
      offerStructure.push({ id: offerId, state: stateSelected, causal: "" })
      setOffersToChangeTable(offersObject)
      setOffersStateToSend({ offers: offerStructure })
    }
  }

  const handleUpdate = (value: string, offerSelected: IGetOffer) => {
    setOfferSelected(offerSelected)
    setUpdateReady(value)
    const depSelected = departmentArray.find(item => item.parameter_code === offerSelected.department);
    setDepartmentSelected(depSelected?.name || '');
    const cityElm = cityArray.find(item => item.parameter_code === offerSelected.city);
    setCity(cityElm?.name ? cityElm.name : '');
  }
  const closeUpdate = () => {
    setUpdateReady('');
  };

  const requestSearch = (name: string, searchVal: string) => {
    const error: string = searchVal !== '' ? Validations(name, searchVal, fieldSearchValidation) || "" : '';
    setFormErrorSearch(prev => ({
      ...prev,
      [name]: error
    }));
    setSearched(searchVal);
    if (searchVal !== '') {
      setSearchActive(true);
      const filteredData = offersFiltered.filter(item => (item.jobTitle.toString()).toLowerCase().includes(searchVal.toLowerCase()));
      setOffersFiltered(filteredData);
    } else {
      setSearchActive(false);
      setOffersFiltered(offersList);
    }
    setInitPage(0);
  }

  const cancelSearch = () => {
    setSearched('')
    requestSearch('searched', '');
    setSearchActive(false)
  }

  return (
    <>
      {containsAny(permissionAdminSeeNewOffers, userContext.userPermissions) ?
        <>
          {updateReady !== "" ?
            <Grid container className="company-info-container">
              {
                updateReady === "See" &&
                <>
                  <Grid container item xs={12} className="back-offers-list-section">
                    <Grid container item xs={12} className="main-container">
                      <Grid container item xs={12} className="back-offers-btn-container">
                        <Button
                          variant="contained"
                          className="step-back-offers"
                          startIcon={<NavigateBeforeIcon />}
                          onClick={closeUpdate}
                        >
                          Regresar a las ofertas
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  <OfferDetailAdmin
                    offerSelected={offerSelected}
                    department={departmentSelected}
                    city={city}
                  />

                </>
              }
            </Grid>
            :
            (offersStateToSend != null && offersStateToSend.offers.length > 0) ?
              <OffersChangeAction offersObject={offersToChangeTable} offersStateToSend={offersStateToSend} backComponent={<OffersAdminView />} />
              :
              <>
                <Grid container item xs={12} className="back-offers-list-section">
                  <ButtonBackListOffers url="/admin/dashboard" text="Ir a dashboard" />
                </Grid>
                <Grid container className="company-info-container">
                  <Grid container className="main-container">
                    <Grid container item xs={12} className="title-container">
                      <DescriptionIcon className="business-icon" />
                      <Typography variant="h2" className="bold-serif" >
                        Ofertas Nuevas
                      </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <AnnouncementFilters title="Filtros de búsqueda" formValues={formValues} formErrors={formErrors} onChangeForm={changeFilterForm} countryCitiesArray={countryCitiesArray} departmentArray={departmentArray} cities={cities} reportForm={false} />
                    </Grid>
                    <Grid container item xs={12}>
                      <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={false} showButtons={true} deleteFilter={handleDeleteChip} isValid={isValid} onApplyFilters={clickApplyFilters} />
                    </Grid>
                    <Grid container item xs={12} className="list-container">
                      <Grid container item xs={12} className="list-view">
                        <Grid item xs={12}>
                          <div className="header-table-container">
                            <div className="search-container-outer">
                              <SearchTable textPlaceHolder="Ingresa un nombre de oferta" title="Buscar por nombre del cargo" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch} />
                            </div>
                          </div>
                        </Grid>
                        <OffersTable onChanhePageSize={onChanhePageSize} rows={offersFiltered} departmentArray={departmentArray} cityArr={cityArray} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleRowOffer={handleRowOffer} handleUpdate={handleUpdate} setCurrentPage={setCurrentPage} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <LoadingLockPage load={promiseInProgress} />
                <LoadingLockPage load={initLoad} />
              </>
          }
        </>
        :
        <NotAuthorized />
      }
    </>
  )
}

export default OffersAdminView