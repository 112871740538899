/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import moment from 'moment';
import ReactQuill from 'react-quill';
import Quill from "quill";
import BlotFormatter from 'quill-blot-formatter';
import WarningIcon from '@material-ui/icons/Warning';
import 'react-quill/dist/quill.snow.css';
import TextField from '@material-ui/core/TextField';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SendIcon from '@material-ui/icons/Send';
import { Link as LinkTo } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import { DateRange } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Validations from '../../helpers/Validations';
import { ChipData } from '../../interfaces/ChipData';
import { UserStateObject } from '../../interfaces/UserStateObject';
import MoreFiltersGraduate from '../ManagementState/MoreFiltersGraduate';
import SimpleFilterBox from '../ManagementState/SimpleFilterBox';
import ShowFilters from '../ManagementState/ShowFilters';
import { sendMassiveEmail, listCampusCurriculum } from '../../services/AdminServices';
import { StateByRole } from '../../interfaces/StateByRole';
import { IGeneralSIA } from '../../interfaces/IGeneralSIA';
import { IGeneralParameter } from '../../interfaces/IGeneralParameter';
import { isLogin } from '../../services/AuthService';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { containsAny, convertToBase64, sortByKey } from '../../helpers/sharedFunctions';
import { GENDER } from '../../services/SIAService';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import { IListParametersInfo } from '../../interfaces/IListParametersInfo';
import { getEmailFrom } from '../../services/ConfigurationService';
import { IGraduateFilterForm } from '../../interfaces/IGraduateFilterForm';
import { IGraduateAttach } from '../../interfaces/IGraduateAttach';
import { IConsultCampus } from '../../interfaces/IConsultCampus';
import { AttachingFile, IGraduateFilterObjMsg } from '../../interfaces/IGraduateFilterObjMsg';
import { getACADEMIC_LEVEL, getASSOCIATION, getCAMPUS_FACULTIES, getPROGRAMS, getTYPE_OF_ASSOCIATION } from '../../services/ParameterService';
import { permissionAdminSendMassMsgGraduates, permissionGraduateActiveInactiveState, permissionGraduateVerifyRejectState } from '../../utils/PermissionsVariables';
import UserContext from '../../utils/UserContext';

import './MassMessage.scss';
import ModalOffersAction from '../LaboralOffers/ModalOffersAction';

Quill.register('modules/blotFormatter', BlotFormatter);

export class UsersStateTosendObj{
    graduates: Array<UserStateObject> = []
}

const filterGraduateValidation = {
    names: {
      error: "",
      validate: "text",
      maxLength: 40,
      required: false
    },
    surname: {
        error: "",
        validate: "text",
        maxLength: 40,
        required: false
    },
    gender: {
        validate: "select",
        required: false
    },
    doc: {
        error: "",
        validate: "textNumber",
        minLength: 6,
        maxLength: 11,
        required: false
    },
    state:{
        validate: "select",
        required: false
    },
    campus: {
        validate: "select",
        required: false
    },
    school: {
        validate: "select",
        required: false
    },
    curriculum: {
        validate: "select",
        required: false
    },
    academicLevel: {
        validate: "select",
        required: false
    },
    typeOfAssociation:{
        validate: "select",
        required: false
    },
    association:{
        validate: "select",
        required: false
    },
    extraEmail:{
        error: "",
        validate: "emailList",
        required: true,
    },
    subject:{
        error: "",
        validate: "textNumber",
        maxLength: 500,
        required: true
    },
    html:{
        error: "",
        validate: "textNumber",
        maxLength: 400,
        required: true
    },
    inJobBank:{
        validate: "specialCheckbox",
        required: true
    }
}

const initialGraduateFilterErrors ={
    names: "",
    surname: "",
    gender: "",
    doc: "",
    state: "",
    updatedAtStart: "",
    updatedAtEnd: "",
    campus: "",
    school: "",
    curriculum: "",
    academicLevel: "",
    graduationStart: "",
    graduationEnd: "",
    typeOfAssociation: "",
    asociation: "",
    inJobBank:"",
    extraEmail:"",
    subject: "",
    html: ""
}

const stateOptionsGraduate: Array<StateByRole> = [
    {id:1, label: 'Inscrito', value:'Inscrito', description: 'Cambiar estado a Inscrito', permission:[] },
    {id:2, label: 'Verificado', value:'Verificado', description: 'Cambiar estado a Verificado', permission:  permissionGraduateVerifyRejectState },
    {id:3, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: permissionGraduateActiveInactiveState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionGraduateActiveInactiveState },
    {id:5, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: permissionGraduateVerifyRejectState }
];

const MassMessageGraduate: React.FC = () => {
    const urlGraduate = "/admin/dashboard/";
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [filterValues, setFilterValues] = useState<IGraduateFilterForm>(new IGraduateFilterForm())
    const [filterValuesAttach, setFilterValuesAttach] = useState<IGraduateAttach>(new IGraduateAttach())
    const [formErrors, setFormErrors] = useState(initialGraduateFilterErrors);
    const [chipData, setChipData] = useState<ChipData[]>([]);
    const [chipDataEmails, setChipDataEmails] = useState<ChipData[]>([]);
    const [gendersType, setGendersType] = useState<Array<IGeneralSIA>>([]);
    const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
    const [campusSelected, setCampusSelected] = useState<number>(0);
    const [schoolSelected, setSchoolSelected] = useState<number>(0);
    const [allPrograms, setAllPrograms] = useState<Array<IGeneralParameter>>([]);
    const [schools, setSchools] = useState<Array<IGeneralParameter>>([]);
    const [programs, setPrograms] = useState<Array<IGeneralParameter>>([]);
    const [allAcademic, setAllAcademic] = useState<Array<IGeneralParameter>>([]);
    const [associationTypeArray, setAssociationTypeArray] = useState<Array<IGeneralParameter>>([]);
    const [associationArray, setAssociationArray] = useState<Array<IGeneralParameter>>([]);
    const [recordsfound, setRecordsFound] = useState<number>(0);
    const [senderMail, setSenderMail] = useState<string>('');
    const [errorMessage, setErrorMessage]= useState('');
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [validIs, setValidIs] = useState<boolean>(false)
    const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
    const [errorDocument, setErrorDocument] = useState<string>('');
    const [aux, setAux] = useState<boolean>(false);
    const [aux2, setAux2] = useState<boolean>(false);
    const [stateCheck, setStateCheck] = useState<any>({
        Inscrito: false,
        Verificado: false,
        Activo: false,
        Inactivo: false,
        Rechazado: false
    });

    const isValid =
        !formErrors.names &&
        !formErrors.surname &&
        !formErrors.doc &&
        !formErrors.updatedAtStart &&
        !formErrors.updatedAtEnd &&
        !formErrors.graduationStart &&
        !formErrors.graduationEnd 
        

    const isValidAddEmail =
        filterValues.extraEmail.length>0 &&
        !formErrors.extraEmail

    const isValidSend =
        filterValues.subject.length>0 &&
        !formErrors.subject &&
        filterValues.html.length>0 &&
        !formErrors.html &&
        (
            (recordsfound>0 && filterValues.inJobBank !== undefined ) 
            || filterValues.aditionalEmails.length>0
        ) 

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
            [{size: []}],
            [{ 'color': [] }, { 'background': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'},
            { 'align': [] },
            { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
        blotFormatter: {},
    }

    const formats = [
        'header', 'font', 'size',
        'color', 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'align', 'indent',
        'link', 'image', 'video'
    ];

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitialData = async()=>{
            setStateCheck({ ...stateCheck, "Activo": false });
            const responseSender:any = await getEmailFrom();
            if(responseSender){
                const emailReceived: any = await responseSender.message.value;
                setSenderMail(emailReceived)
            }
            const userToConsult = {
                userId: userContext.userId,
                roleId: userContext.roleSelected
            }
            if(userContext.roleSelected === 4 || userContext.roleSelected === 6){
                const responseConsult = await (listCampusCurriculum(
                    {...userToConsult}
                    ))
                if(responseConsult.status === 200){
                    const result = await responseConsult.json();
                    setCampusSelected(result.campus)
                    setSchoolSelected(result.school)
                    if(userContext.roleSelected === 4){
                        setAux(true)
                    }
                    if(userContext.roleSelected === 6){
                        setAux(true)
                        setAux2(true)
                    }
                    
                } else {
                    setOpenModalError(true);
                    setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general")
                }
            }
            // data for campus field
            let dataAllCampusRes: any = await getCAMPUS_FACULTIES();
            let dataAllCampus = dataAllCampusRes.list
            dataAllCampus = sortByKey(dataAllCampus, 'parentName');            
            setAllCampus(dataAllCampus ? dataAllCampus : []);
            // data for programs
            let dataAllPrograms: any = await getPROGRAMS();
            dataAllPrograms = sortByKey(dataAllPrograms, 'name')
            setAllPrograms(dataAllPrograms ? dataAllPrograms : []);
            // data for all academic levels
            let responseAcademic: any = await getACADEMIC_LEVEL();
            responseAcademic = sortByKey(responseAcademic, 'name')
            setAllAcademic(responseAcademic);
            //data for gender field
            const dataGender:Array<IGeneralSIA> = GENDER;
            setGendersType(dataGender)
            // data for association type
            let resAssociationType: any = await getTYPE_OF_ASSOCIATION();
            setAssociationTypeArray(resAssociationType);
            // data for association
            let resAssociation: any = await getASSOCIATION();
            resAssociation = sortByKey(resAssociation, 'name');
            setAssociationArray(resAssociation);
            setInitLoad(false);
            
        }
        
        getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        setSchools([]);
        setPrograms([]);
        setFilterValues(prev => ({
            ...prev,
            school: [],
            curriculum: []
        }))
        if(filterValues.campus>0){
            const campusFound = allCampus.find(campus=> campus.id === filterValues.campus);
            if(campusFound){
              const campus: IListParametersInfo = campusFound;
              let schools = campus.childs;
              schools = sortByKey(schools, 'name');
              setSchools(schools);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.campus])

    useEffect(()=>{
        const getPrograms = async()=>{
            if(filterValues.school.length>0){
                let programsArr: IGeneralParameter[] = []
                filterValues.school.forEach(obj=>{
                    const schoolPrograms: IGeneralParameter[] = allPrograms.filter(item=>item.parentId === obj.parameter_code);
                    if(schoolPrograms.length>0){
                        programsArr.push(...schoolPrograms);
                    }
                })
                if (programsArr.length>0){
                    programsArr = sortByKey(programsArr, 'name')
                    setPrograms(programsArr)
                    let newCurriculum: Array<IGeneralParameter> = [];
                    filterValues.curriculum.forEach(option=>{
                        const found = programsArr.find(item=>item.name === option.name);
                        if(found){
                            newCurriculum.push(found)
                        }
                    })
                    setFilterValues(prev => ({
                        ...prev,
                        curriculum: newCurriculum
                    }));
                }else {
                    setPrograms(programsArr)
                    setFilterValues(prev => ({
                        ...prev,
                        curriculum: []
                    }));
                }
            } else {
                setPrograms([])
                setFilterValues(prev => ({
                    ...prev,
                    curriculum: []
                }));
            }
        }
        getPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.school])

    const handleChangeEditor = (value: any) => {
        if(value.length>0 && value !== "<p><br></p>"){
          setFilterValues((prev:any) => ({
            ...prev,
            html: value
          }));
        } else{
            setFilterValues((prev:any) => ({
            ...prev,
            html: ""
          }));
        }
    };

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateCheck({ ...stateCheck, [event.target.name]: event.target.checked });
    };

    const changeFilterForm = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name);
        if (name === 'inJobBank1'){
            value = e.target.checked   
            name = 'inJobBank';
        }else if ( name === 'inJobBank2'){
            value = !e.target.checked 
            name = 'inJobBank';
        } else if( name === "extraEmail") {
            value = e.target.value.split(/\n+/)  // Split by new lines
            .map((email:string) => email.trim())  // Trim spaces
            .filter((email:string) => email)  // Remove empty entries
            .join(", ");
        }
        setFilterValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, filterGraduateValidation) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
        setFilterValues(prev => ({
            ...prev,
            [name]: dateValue
        }));
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
        let labelStart: string = "";
        let labelEnd: string = "";
        const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
        const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
        if(name === "updateDate"){
            labelStart = "updatedAtStart";
            labelEnd = "updatedAtEnd";
        }
        if(name === "graduationDate"){
            labelStart = "graduationStart";
            labelEnd = "graduationEnd";
        }
        errorDates(labelStart, valueStart);
        errorDates(labelEnd, valueEnd);
    }

    const errorDates = (label: string, value: string) => {
        setFormErrors(prev => ({
            ...prev,
            [label]: value
        }));
    }

    const tranformDataGraduateMsg = (values: IGraduateFilterForm, preview:boolean)=>{
        const temporaryObject: IGraduateFilterObjMsg = new IGraduateFilterObjMsg();
        temporaryObject.type = "graduate";
        temporaryObject.preview = preview;
        if(values.names !== ""){
            temporaryObject.filters.names = values.names;
        } else { delete temporaryObject.filters.names }
        if(values.surname !== ""){
            temporaryObject.filters.surname = values.surname;
        } else { delete temporaryObject.filters.surname }
        if(values.gender >0){
            temporaryObject.filters.gender = values.gender;
        } else { delete temporaryObject.filters.gender }
        if(values.doc !== ""){
            temporaryObject.filters.doc = values.doc;
        } else { delete temporaryObject.filters.doc }
        if(values.state.length>0){
            temporaryObject.filters.state = values.state.map(item=>item.value);
        } else { delete temporaryObject.filters.state }
        if(values.state.length>0){
            temporaryObject.filters.state = values.state.map(item=>item.value);
        } else { delete temporaryObject.filters.state }
        if(values.updateDate[0] !== null && values.updateDate[1] !== null){
            if(temporaryObject.filters.rangeUpdate){
                temporaryObject.filters.rangeUpdate.sinceDate = moment(values.updateDate[0]).format("YYYY-MM-DD");
                temporaryObject.filters.rangeUpdate.untilDate = moment(values.updateDate[1]).format("YYYY-MM-DD");
            }
        } else { delete temporaryObject.filters.rangeUpdate }
        const foundSelected = Object.keys(stateCheck).filter(item=>stateCheck[item]===true);
        if(foundSelected.length>0){
            temporaryObject.filters.state = foundSelected
        } else { delete temporaryObject.filters.state }
        if(values.campus > 0){
            temporaryObject.educationUnal.campus = values.campus;
        } else { delete temporaryObject.educationUnal.campus }
        if(values.school.length > 0){
            temporaryObject.educationUnal.school = values.school.map(item=>item.parameter_code);
        } else { delete temporaryObject.educationUnal.school }
        if(values.curriculum.length > 0){
            temporaryObject.educationUnal.curriculum = values.curriculum.map(item=>item.parameter_code);
        } else { delete temporaryObject.educationUnal.curriculum }
        if(values.academicLevel > 0){
            temporaryObject.educationUnal.academicLevel = values.academicLevel;
        } else { delete temporaryObject.educationUnal.academicLevel }
        if(values.graduationDate[0] !== null && values.graduationDate[1] !== null){
            if(temporaryObject.educationUnal.rangeGrade){
                temporaryObject.educationUnal.rangeGrade.sinceDate = moment(values.graduationDate[0]).format("YYYY-MM-DD");
                temporaryObject.educationUnal.rangeGrade.untilDate = moment(values.graduationDate[1]).format("YYYY-MM-DD");
            }
        } else { delete temporaryObject.educationUnal.rangeGrade }
        if(values.academicLevel > 0){
            temporaryObject.educationUnal.academicLevel = values.academicLevel;
        } else { delete temporaryObject.educationUnal.academicLevel }
        if(values.typeOfAssociation > 0){
            temporaryObject.associations.typeOfAssociation = values.typeOfAssociation;
        } else { delete temporaryObject.associations.typeOfAssociation }
        if(values.association > 0){
            temporaryObject.associations.association = values.association;
        } else { delete temporaryObject.associations.association }
        if(filterValues.subject !== ""){
            temporaryObject.subject = filterValues.subject;
        }
        if(filterValues.html === "" || filterValues.html ==="<p><br></p>"){
            temporaryObject.html = "";
        } else {
            temporaryObject.html = filterValues.html;
        }
        if(filterValues.aditionalEmails.length>0){
            temporaryObject.aditionalEmails = filterValues.aditionalEmails;
        }
        if(filterValues.attachingFiles.length>0){
            filterValues.attachingFiles.map(item=>delete item.previewUrl);
            temporaryObject.attachingFiles = filterValues.attachingFiles;
        }
        if(values.inJobBank !== undefined){
            temporaryObject.filters.inJobBank = values.inJobBank;
        } else { delete temporaryObject.filters.inJobBank }
        
        return temporaryObject;
    }
    

    const clickApplyFilters = async(value:any, action:string)=>{
        if(action === 'apply'){
            const objectToSend = tranformDataGraduateMsg(filterValues, true);
            const chipLabels = chipData.map(chip => chip.label).join('|');
            const responseMassiveMsg: any = await trackPromise(sendMassiveEmail(
                userToken,
                objectToSend,
                chipLabels
            ))
            if(responseMassiveMsg.status === 200){
                const result = await responseMassiveMsg.json();
                setRecordsFound(result.total);
                setChips();
            } else {
                setOpenModalError(true);
                setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
            }
        } else if (action === 'clear'){
            setChipData([]);
            setFilterValues(new IGraduateFilterForm());
            setFormErrors(initialGraduateFilterErrors);
            setRecordsFound(0);
        }
    }

    useEffect(()=>{
        if(isValid){
            setChips();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, isValid])

    const setChips =()=>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        let subIndex =0;
        const fieldNames=[
            {key: 'names', description: 'Nombres'},
            {key: 'surname', description: 'Apellidos'},
            {key: 'gender', description: 'Género'},
            {key: 'doc', description: 'No de documento'},
            {key: 'campus', description: 'Sede'},
            {key: 'school', description: 'Facultad'},
            {key: 'curriculum', description: 'Programa'},
            {key: 'academicLevel', description: 'Nivel educativo'},
            {key: 'updateDate', description: 'Fecha de actualización'},
            {key: 'graduationDate', description: 'Fecha de graduación'},
            {key: 'typeOfAssociation', description: 'Tipo de asociación'},
            {key: 'association', description: 'Asociación'},
            {key: 'inJobBank', description: 'En bolsa de empleo'}

        ]
        for (const [key, value] of Object.entries(filterValues)) {
            if(value!==0 && value!=='' && value!==null && value !== undefined && value.length!==0 && key !=="subject" && key !=="html" && key !=="extraEmail" && key !=="aditionalEmails" ){
                if(value[0]!==null && value[1]!==null ){
                    let obj;
                    const fieldObj = fieldNames.find(field=>field.key === key);
                    if(key === 'gender'){
                        obj = gendersType.find(gender=>gender.id === value);
                    } else if (key === 'campus'){
                        subIndex = index;
                        const tempCampus = allCampus.find(campus=>campus.id === value);
                        if(tempCampus){
                            tempArray.push({key: subIndex, name:tempCampus.parentName + ' campus', label: fieldObj?.description + ': ' + tempCampus?.parentName });
                            subIndex++;
                        }
                    } else if (key === 'school'){
                        subIndex = index;
                        (value as IGeneralParameter[]).forEach(schoolItem=>{
                            tempArray.push({key: subIndex, name:schoolItem.name + ' school', label: fieldObj?.description + ': ' + schoolItem.name });
                            subIndex++;
                        });
                    } else if (key === 'curriculum'){
                        subIndex = index;
                        (value as IGeneralParameter[]).forEach(programItem=>{
                            tempArray.push({key: subIndex, name:programItem.name + ' curriculum', label: fieldObj?.description + ': ' + programItem.name });
                            subIndex++;
                        });
                    } else if (key === 'academicLevel'){
                        obj = allAcademic.find(academic=>academic.parameter_code === value);
                    } else if (key === 'typeOfAssociation'){
                        obj = associationTypeArray.find(assoType=>assoType.parameter_code === value);
                    } else if (key === 'association'){
                        obj = associationArray.find(asso=>asso.parameter_code === value);
                    } else if (key === 'updateDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    } else if (key === 'graduationDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    } else if (key === 'inJobBank'){
                        obj = {name : value === true ? 'Si' : 'No'}
                    }
                    if(subIndex !==0){
                        index = subIndex
                        subIndex = 0;
                    }
                    if(obj){
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                    } else {
                        if(key !== 'campus' && key !== 'school' && key !== 'curriculum'){
                            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                        }
                    }
                    index++;
                }
            }
        }
        setChipData(tempArray)
        if (filterValues.inJobBank !== undefined){
            setValidIs(true)
        }else{
            setValidIs(false)
        }
        if(userContext.roleSelected === 4){
            if(filterValues.campus>0 && stateCheck ){
            setValidIs(true)
            }else{
                setValidIs(false)
            }
        }else if (userContext.roleSelected === 6){
            if(filterValues.campus>0 && filterValues.school.length>0){
                setValidIs(true)
            }else{
                setValidIs(false)
            }
        }
    };

    const handleDeleteChip = (chipToDelete: ChipData) => () => {
        const name =chipToDelete.name;
        setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        if(name === 'gender' || name === 'academicLevel'){
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: 0
            }));
        } else if (name.includes('campus')){
            setFilterValues(prev => ({
                ...prev,
                campus: 0
            }));
        } else if(name === 'updateDate' || name === "graduationDate"){
            setFilterValues(prev => ({
                ...prev,
                [name]: [null,null]
            }));
        } else if(name.includes('school')){
            const newSchools = filterValues.school.filter(item=>item.name !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
                ...prev,
                school: newSchools
            }));
        } else if(name.includes('curriculum')){
            const newCurriculum = filterValues.curriculum.filter(item=>item.name !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
                ...prev,
                curriculum: newCurriculum
            }));
        } else if(name === 'inJobBank'){
            setFilterValues(prev => ({
                ...prev,
                [name]: undefined
            }));
        }else {
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: ''
            }));
        }
    };

    const addEmail = () => {

        const foundEmails = filterValues.extraEmail.split(",").map(email => email.trim());
    
        const tempArrayEmails: ChipData[] = [
            ...filterValues.aditionalEmails.map((email, i) => ({ key: i, name: email, label: email })),
            ...foundEmails.map((email, i) => ({ key: filterValues.aditionalEmails.length + i, name: email, label: email }))
        ];
    
        setFilterValues(prev => ({
            ...prev,
            aditionalEmails: [...prev.aditionalEmails, ...foundEmails],
            extraEmail: ''
        }));
    
        setChipDataEmails(tempArrayEmails);
    }

    const handleDeleteEmail = (chipToDelete: ChipData) => () => {
        const name =chipToDelete.name;
        setChipDataEmails((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        const newArrayEmails = filterValues.aditionalEmails.filter(chip=>name !==chip);
        setFilterValues(prev=>({
            ...prev,
            aditionalEmails: newArrayEmails
        }))
    }

    const confirmSend = async()=>{
        setOpenModalConfirm(true);
    }

    const sendEmail = async()=>{
        setOpenModalConfirm(false);
        filterValues.attachingFiles = filterValuesAttach.attachingFiles
        const objectToSend = tranformDataGraduateMsg(filterValues, false);
        const chipLabels = chipData.map(chip => chip.label).join('|');
        const responseMassiveMsg: any = await trackPromise(sendMassiveEmail(
            userToken,
            objectToSend,
            chipLabels
        ))
        if(responseMassiveMsg.status === 200){
            setOpenModalSuccess(true);
            setSuccessMessage("Mensaje enviado");
            setChips();
        } else {
            setOpenModalError(true);
            setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general")
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
        window.location.reload();
    };

    const handleUploadDocuments = async (e: any) => {
        setErrorDocument('');
        const { name } = e.target;
    
        if (name === "otherFiles" && e.target.files.length > 0) {
            const file = e.target.files[0];
            const allowedTypes = [
                "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.oasis.opendocument.text", "application/vnd.oasis.opendocument.spreadsheet",
                "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/vnd.oasis.opendocument.presentation", "image/jpeg", "image/png", "image/gif",
                "image/svg+xml", "image/bmp", "image/tiff", "text/csv", "text/plain", "application/json",
                "application/xml", "application/zip", "application/x-rar-compressed", "application/x-7z-compressed",
                "video/mp4", "video/x-msvideo", "video/quicktime"
            ];
    
            if (allowedTypes.includes(file.type)) {
                const base64File: any = await convertToBase64(file);
                if (base64File.length > 0) {
                    const newFileBase64: AttachingFile = {
                        base64: base64File,
                        type: file.type,
                        filename: file.name,
                        previewUrl: URL.createObjectURL(file)
                    };
    
                    setFilterValuesAttach(prev => ({
                        ...prev,
                        attachingFiles: [...prev.attachingFiles, newFileBase64]
                    }));
                }
            } else {
                setErrorDocument('Solo puede subir archivos en formatos permitidos.');
            }
        }
    };

    const handleDeleteFile = async(fileToDelete:AttachingFile) => {
        const newFiles = filterValuesAttach.attachingFiles && filterValuesAttach.attachingFiles.filter((obj: AttachingFile)=>obj.filename !== fileToDelete.filename);
        setFilterValuesAttach(prev=>({
            ...prev,
            attachingFiles:newFiles
        }))
    }

    const closeModalConfirm = () =>{
        setOpenModalConfirm(false);
    } 

    useEffect(() => {
        if(userContext.roleSelected === 5){
            setValidIs(true)
        }
    }, [])
    
    return(
        <>
            {isLogin() ?
                <>
                    <Grid container className="main-container">
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={4}>
                                <div className="title-section-line">
                                    <EmailIcon className="group-icon"/>
                                    <Typography variant="h2" className="message-title" >
                                        Mensajería masiva egresados
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} className="line" />
                            <Grid item xs={12} md={12} lg={12}>
                                <div className="title-section-line">
                                    <Typography variant="h3" className="message-title" >
                                        <b className="red-title">Importante:</b> Si necesita enviar un correo a todos los egresados activos en el SIE, se recomienda sobre la subsección de Estado del egresado aplicar el estado “Activo” o los filtros que se requieran.
                                    </Typography>
                                </div>
                                
                                {
                                    userContext.roleSelected === 4?   
                                    <>
                                    <Grid item xs={12} md={12} className="line" />
                                    <div className="title-section-line">
                                        <Typography variant="h3" className="message-title" >
                                            <b className="red-title">Importante:</b> Señor administrador(a): Para poder filtrar su población de egresados debe seleccionar su sede     
                                        </Typography>
                                    </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                                {
                                    userContext.roleSelected === 6?
                                    <>
                                    <Grid item xs={12} md={12} className="line" />
                                    <div className="title-section-line">
                                        <Typography variant="h3" className="message-title" >
                                            <b className="red-title">Importante:</b> Señor administrador(a): Para poder filtrar su población de egresados debe seleccionar su sede y facultad
                                        </Typography>
                                    </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <SimpleFilterBox title="Estado del egresado" subtitle="Filtrar por estados:" stateCheck={stateCheck} onChangeState={handleChangeState} stateOptions={stateOptionsGraduate}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <MoreFiltersGraduate titleSection="Agregar otros filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} gendersType={gendersType} allCampus={allCampus} schools={schools} programs={programs} allAcademic={allAcademic} associationTypeArray={associationTypeArray} associationArray={associationArray} reportForm={false} stateOptions={ stateOptionsGraduate} campusSelected={campusSelected} aux={aux} aux2={aux2} schoolSelected={schoolSelected}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={stateCheck} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={validIs} onApplyFilters={clickApplyFilters} />
                        </Grid>

                        <Grid container item xs={12} className="mass-message-container">
                            <Grid container item xs={12} className="message-container">
                                <Grid container item xs={12} className="title-container-message">
                                    <Typography variant="h2" className="title">
                                        Destinatarios
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} direction="row" className="message-content">
                                    {recordsfound>0 &&
                                        <Grid item xs={12} className="field-box-margin">
                                            <Typography variant="h4" className="field-box-title-graduate">
                                                Total de egresados encontrados: {recordsfound}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid container item xs={12} className="line" />
                                    <Grid item xs={12} md={12} className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-graduate">
                                            Añadir correos manualmente
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            name="extraEmail"
                                            placeholder="Correos adicionales"
                                            variant="outlined"
                                            value={filterValues.extraEmail || ""}
                                            onChange={changeFilterForm}
                                            error={!!formErrors.extraEmail}
                                            helperText={formErrors.extraEmail}
                                            margin="none"
                                        />
                                    </Grid>
                                    <Grid container item xs={12} md={12} className="field-box-margin" justify="flex-end" alignItems="flex-end">
                                        <div>
                                            <Button disabled={!isValidAddEmail} variant="contained" className="add-email-button" onClick={addEmail}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Adicionar correo
                                                </Typography>
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    {chipDataEmails.length>0 &&
                                        <Grid container item xs={12}>
                                            <ShowFilters titleSection="Correos agregados" filtersData={chipDataEmails} stateCheck={stateCheck} showButtons={false} showDelete={true} deleteFilter={handleDeleteEmail}  />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} className="message-container">
                                <Grid container item xs={12} className="title-container-message">
                                    <Typography variant="h2" className="title">
                                        Contenido
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} direction="row" className="message-content">
                                    <Grid item xs={12} sm={6} className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-graduate link-responsive" >
                                            Remitente: {senderMail}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    <Grid item xs={12} className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-graduate">
                                            Asunto*
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="subject"
                                            placeholder="Asunto"
                                            variant="outlined"
                                            value={filterValues.subject || ""}
                                            onChange={changeFilterForm}
                                            error={!!formErrors.subject}
                                            helperText={formErrors.subject}
                                            margin="none"
                                        />
                                    </Grid>
                                    <Grid item xs={12}  className="field-box-margin">
                                        <Typography variant="h4" className= "field-box-title-graduate" >
                                            Cuerpo del correo*
                                        </Typography>
                                        <Grid item xs={12}>
                                            <ReactQuill
                                                placeholder="Crea un descripcion detallada en este espacio"
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    minHeight: "100px",
                                                }}
                                                value={filterValues.html}
                                                onChange={handleChangeEditor}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    <Grid item xs={12}  className="documents-mass-message">
                                        <Typography variant="h5" className="description-documents-text regular-serif">En esta sección puede adjuntar documentos que desea enviar como adjuntos en el correo. Para adjuntar el documento de clic en el botón "Adjuntar archivo" y seleccione el documento que desea adjuntar. El documento debe estar en formato pdf.</Typography>
                                        {
                                            filterValuesAttach.attachingFiles.length>0 &&
                                            <Grid container item xs={12} justify="flex-start" direction="column">
                                                { filterValuesAttach.attachingFiles.map((file, index)=>(
                                                    <div key={index} className="list-file-element">
                                                        <Link target="_blank" rel="noopener" href={file.previewUrl}>
                                                            <Typography align="center" variant="h6" className="regular-serif">
                                                                {file.filename}
                                                            </Typography>
                                                        </Link>
                                                        <IconButton className="ico-delete" onClick={()=>handleDeleteFile(file)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            </Grid>
                                        }
                                        <Typography variant="h6" className="Regular-serif primaryError">
                                            {errorDocument}
                                        </Typography>
                                        <Grid container item xs={12} justify="flex-start">
                                            <div className="upload-button-container">
                                                <Button variant="contained" component="label" className="upload-button">
                                                    <Typography variant="h5" className="regular-serif">
                                                        Adjuntar archivo
                                                    </Typography>
                                                    <div>
                                                        <input type="file" name="otherFiles" onChange={handleUploadDocuments} accept=".pdf,.doc,.docx,.odt,.xls,.xlsx,.ods,.ppt,.pptx,.odp,.jpg,.jpeg,.png,.gif,.svg,.bmp,.tiff,.zip,.rar,.7z,.csv,.txt,.json,.xml,.mp4,.avi,.mov" hidden/>
                                                    </div>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    <Grid container item xs={12} className="container-buttons-message">
                                        <Button variant="contained" className="cancel-button" component={ LinkTo } to={urlGraduate} startIcon={<ChevronLeftIcon />} >
                                            <Typography variant="h5" className="regular-serif">
                                                Cancelar
                                            </Typography>
                                        </Button>
                                        {containsAny(permissionAdminSendMassMsgGraduates, userContext.userPermissions) &&
                                            <Button disabled={!isValidSend} variant="contained" className="send-button" endIcon={<SendIcon />} onClick={confirmSend}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Enviar
                                                </Typography>
                                            </Button>
                                        }
                                        <ModalOffersAction
                                            open={opeModalConfirm}
                                            handleClose={closeModalConfirm}
                                            handleContinue={sendEmail}
                                            iconModal={<WarningIcon className="big-info-icon" />}
                                            title="¿Está seguro de enviar el correo?"
                                            headerdescription=""
                                            description="Esta intentando enviar un correo a uno o mas destinatarios"
                                            backBtnText="Regresar"
                                            colorBtnBack= ""
                                            continueBtnText="Si, Continuar"
                                            colorBtnContinue="#14A5DC"
                                            valid={true}
                                        />
                                        <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                                        <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <LoadingLockPage load={promiseInProgress}/>
                    <LoadingLockPage load={initLoad}/>
                </>
            :
                <Redirect to ="/"/>
            }
        </>
    )
}

export default MassMessageGraduate;