import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import Grid from "@material-ui/core/Grid";
import ReactQuill from 'react-quill';
import Quill from "quill";
import BlotFormatter from 'quill-blot-formatter';
import 'react-quill/dist/quill.snow.css';
import WarningIcon from "@material-ui/icons/Warning";
import esLocale from "date-fns/locale/es";
import IconButton from '@material-ui/core/IconButton';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import DateFnsUtils from '@date-io/date-fns';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import { DatePicker, TimePicker, LocalizationProvider } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { IRegisterEvent } from '../../../interfaces/IRegisterEvent';
import { IRegisterEventData } from '../../../interfaces/IRegisterEventData';
import { createEventsNews, updateEventsNews } from '../../../services/NewsEventsService';
import NotificationBox from '../../ShareComponents/NotificationBox';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IFile } from '../../../interfaces/IFile';
import { IRegisterNewData } from '../../../interfaces/IRegisterNewData';
import { getSignUrl, uploadFileSignedUrl } from '../../../services/s3Service';
import Validations from '../../../helpers/Validations';
import { IPresignedUrl, IPreSignedUrlReq } from '../../../interfaces/IPreSignedUrl';
import ModalOffersAction from '../../LaboralOffers/ModalOffersAction';
import UserContext from '../../../utils/UserContext';

import './NewsEventsForm.scss';
import { IRegisterTraining } from '../../../interfaces/IRegisterTraining';

Quill.register('modules/blotFormatter', BlotFormatter);

const formValidations = {
  type: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  category: {
    validate: "select",
    required: true
  },
  name: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  shortDescription: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    maxLength: 300,
    required: true
  },
  description: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    required: true
  },
  dateStart: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    required: true
  },
  timeStart: {
    error: "",
    validate: "normalTime",
    minTime: new Date(),
    required: true
  },
  dateFinish: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    required: true
  },
  timeFinish: {
    error: "",
    validate: "normalTime",
    minTime: new Date(),
    required: true
  },
  place: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  population: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },  
}

interface NewsEventFormProps {
  action?: string;
  infoUpdated: boolean;
  setInfoUpdated: any;
  formValues: IRegisterEvent;
  setFormValues: any;
  formErrors: any;
  setFormErrors: any;
  newDetail?: IRegisterEvent;
  previewButton?: any;
  categoryArray: Array<IGeneralParameter>;
  cancelAction: any;
  setAlreadySaved: any;
  setFormChanged: any;
}

const NewsEventsForm: React.FC<NewsEventFormProps> = ({ action, infoUpdated, setInfoUpdated, formValues, setFormValues, formErrors, setFormErrors, newDetail, previewButton, categoryArray, cancelAction, setAlreadySaved, setFormChanged }) => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [openModalSave, setOpenModalSave] = useState<boolean>(false);
  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
  const [stateToChange, setStateToChange] = useState<string>("");
  const [idSelected, setIdSelected] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const isValid =
    formValues.type.length > 0 &&
    !formErrors.type &&
    formValues.category > 0 &&
    formValues.name.length > 0 &&
    !formErrors.name &&
    formValues.description.length > 0 &&
    !formErrors.description &&
    formValues.shortDescription.length > 0 &&
    !formErrors.shortDescription &&
    !formErrors.fileError &&
    ((
      formValues.type === "Noticia"
    ) ||
      (
        formValues.type === "Capacitacion" && 
        !formErrors.population
      ) ||
      (
        formValues.type === "Instructivo" && 
        !formErrors.population
      ) ||
      (
        formValues.type === "Evento" &&
        moment(formValues.dateStart).isValid() &&
        !formErrors.dateStart &&
        moment(formValues.timeStart).isValid() &&
        !formErrors.timeStart &&
        moment(formValues.dateFinish).isValid() &&
        !formErrors.dateFinish &&
        moment(formValues.timeFinish).isValid() &&
        !formErrors.timeFinish &&
        formValues.place.length > 0 &&
        !formErrors.place
      ))

  const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
            [{size: []}],
            [{ 'color': [] }, { 'background': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'},
            { 'align': [] },
            { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
        blotFormatter: {}
    }

  const formats = [
        'header', 'font', 'size',
        'color', 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'align', 'indent',
        'link', 'image', 'video'
    ];

  useEffect(() => {
    if (action === "Edit" && !infoUpdated) {
      const formData = async () => {
        if (newDetail) {
          for (let [key, value] of Object.entries(newDetail)) {
            setFormValues((prev: any) => ({
              ...prev,
              [key]: value
            }));
          }
          if (newDetail.state === "Publicado") {
            setFormValues((prev: any) => ({
              ...prev,
              publish: true
            }));
          }
          if (formValues.files.length === 0) {
            if (newDetail.files.length === 0 && newDetail.image.length > 0) {
              const url = newDetail.image;
              const fileName = `Imagen-${newDetail.type}-${newDetail.id}.jpg`;

              fetch(url)
                .then(async response => {
                  const blob = await response.blob()
                  const file = new File([blob], fileName, { type: "image/jpeg" });
                  if (file) {
                    setFormValues((prev: any) => ({
                      ...prev,
                      files: [{ file: file, previewUrl: URL.createObjectURL(file) }]
                    }));
                  }
                })
            }
          }
        }
        setInfoUpdated(true)
      }
      formData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, newDetail])

  const handleCloseModalSuccess = () => {
    setOpenModalSuccess(false);
    setAlreadySaved(true);
    setChangeState(true);
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
  };

  const handleChangeEditor = (value: any) => {
    if (value !== "") {
      setFormValues((prev: any) => ({
        ...prev,
        description: value
      }));
    } else {
      setFormValues((prev: any) => ({
        ...prev,
        description: ""
      }));
    }
  };

  const handleChange = (e: any) => {
    setFormChanged(true);
    const { name } = e.target;
    if (name === 'files') {
      if (formValues.files.length < 1 && e.target.files.length > 0) {
        if (e.target.files[0].type === 'image/jpeg') {
          setFormValues((prev: any) => ({
            ...prev,
            [name]: [{ file: e.target.files[0], previewUrl: URL.createObjectURL(e.target.files[0]) }]
          }));
          setFormErrors((prev: any) => ({
            ...prev,
            fileError: ''
          }
          ));
        } else {
          setFormErrors((prev: any) => ({
            ...prev,
            fileError: 'Solo puede subir imagenes en formato jpg'
          }));
        }
      } else {
        if (e.target.files.length > 0) {
          setFormErrors((prev: any) => ({
            ...prev,
            fileError: 'No puede subir mas archivos, si desea cambiar o eliminar la imagen principal, utilice el botón eliminar ubicado en la parte superior izquierda de la imagen y cargue de nuevo otra imagen'
          }));
        }
      }
    } else if (name === "publish" || name === "highlight") {
      const { checked } = e.target;
      setFormValues((prev: any) => ({
        ...prev,
        [name]: checked
      }));
    } else {
      if (name === "type") {
        setFormValues((prev: any) => ({
          ...prev,
          place: ''
        }));
      }
      const { value } = e.target;
      setFormValues((prev: any) => ({
        ...prev,
        [name]: value
      }));
      const error = Validations(name, value, formValidations) || "";
      setFormErrors((prev: any) => ({
        ...prev,
        [name]: error
      }));
    }
  }

  const handleDeleteFile = () => {
    setFormValues((prev: any) => ({
      ...prev,
      files: [],
      signedUrllArr: []
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      fileError: ''
    }));
  }

  const handleSave = () => {
    setOpenModalSave(true);
  }

  const closeModalSave = () => {
    setOpenModalSave(false);
  }

  const requestSave = async () => {
    setOpenModalSave(false);
    let objToSave: any = {};
    if (formValues.type === "Noticia") {
      const newToSave: IRegisterNewData = new IRegisterNewData(formValues);
      newToSave.fileType = 'jpg';
      objToSave = newToSave;
    } else if (formValues.type === "Evento") {
      const eventToSave: IRegisterEventData = new IRegisterEventData(formValues);
      eventToSave.fileType = 'jpg';
      objToSave = eventToSave;
    } else {
      const eventToSave: IRegisterTraining = new IRegisterTraining(formValues);
      eventToSave.fileType = 'jpg';
      objToSave = eventToSave;
    }

    if (action === "Edit" && formValues.id) {
      objToSave.id = formValues.id;
      const responseEventUpdated: Response = await trackPromise(updateEventsNews(
        { ...objToSave, modifyUser: userContext.userId }
      ));
      if (responseEventUpdated.status === 200) {
        setAlreadySaved(true)
        if (formValues.files.length > 0 && formValues.files[0].file) {
          const fileToUpload: IPreSignedUrlReq = {
            contentType: "image/jpeg",
            fileKey: `Imagen-${formValues.type}-${objToSave.id}`,
            folderId: `${formValues.type}-${objToSave.id}`,
            description: "",
            isPublic: true
          }
          const resultURL: IPresignedUrl = await getSignUrl(fileToUpload);
          if (resultURL) {
            const urlPresigned = resultURL.uploadURL;
            const urlImagePublic = urlPresigned.split("?")[0];
            const file: File = formValues.files[0].file;
            const resultFileSaved: Response = await trackPromise(uploadFileSignedUrl(urlPresigned, file));
            if (resultFileSaved.status === 200) {
              const imageObject = {
                id: objToSave.id,
                image: urlImagePublic,
                publish: formValues.publish
              }
              const responseImgUpdated: Response = await trackPromise(updateEventsNews(
                { ...imageObject, modifyUser: userContext.userId }
              ));
              if (responseImgUpdated.status === 200) {
                setSuccessMessage("El recurso se ha modificado con exito");
                setOpenModalSuccess(true);
              } else {
                setErrorMessage("Se produjo un error durante el registro");
                setOpenModalError(true);
              }
            } else {
              setErrorMessage("Se produjo un error durante el registro");
              setOpenModalError(true);
            }
          } else {
            setErrorMessage("Se produjo un error durante el registro");
            setOpenModalError(true);
          }
        } else {
          setSuccessMessage("El recurso se ha modificado con exito");
          setOpenModalSuccess(true);
        }
      } else if (responseEventUpdated.status === 400) {
        setErrorMessage("espacio para error del servicio");
        setOpenModalError(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    } else {
      const responseCOF: Response = await trackPromise(createEventsNews(
        { ...objToSave, modifyUser: userContext.userId }
      ));
      if (responseCOF.status === 200) {
        setAlreadySaved(true);
        const resultEventSaved = (await responseCOF.json()).publishing;
        if (formValues.files.length > 0 && formValues.files[0].file) {
          const fileToUpload: IPreSignedUrlReq = {
            contentType: "image/jpeg",
            fileKey: `Imagen-${formValues.type}-${resultEventSaved.id}`,
            folderId: `${formValues.type}-${resultEventSaved.id}`,
            description: "",
            isPublic: true
          }
          const resultURL: IPresignedUrl = await getSignUrl(fileToUpload);
          if (resultURL) {
            const urlPresigned = resultURL.uploadURL;
            const urlImagePublic = urlPresigned.split("?")[0];
            const file: File = formValues.files[0].file;
            const resultFileSaved: Response = await trackPromise(uploadFileSignedUrl(urlPresigned, file));
            if (resultFileSaved.status === 200) {
              const imageObject = {
                id: resultEventSaved.id,
                image: urlImagePublic,
                publish: formValues.publish
              }
              const responseImgUpdated: Response = await trackPromise(updateEventsNews(
                { ...imageObject, modifyUser: userContext.userId }
              ));
              if (responseImgUpdated.status === 200) {
                setSuccessMessage("El recurso se ha creado con exito");
                setOpenModalSuccess(true);
              } else {
                setErrorMessage("Se produjo un error durante el registro");
                setOpenModalError(true);
              }
            } else {
              setErrorMessage("Se produjo un error durante el registro");
              setOpenModalError(true);
            }
          } else {
            setErrorMessage("Se produjo un error durante el registro");
            setOpenModalError(true);
          }
        } else {
          setSuccessMessage("El recurso se ha creado con exito");
          setOpenModalSuccess(true);
        }
      } else if (responseCOF.status === 400) {
        setErrorMessage("espacio para error del servicio");
        setOpenModalError(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    }
  }

  // Handle form date change
  const handleChangeDate = (dateValue: Date | null, nameField: string) => {
    setFormChanged(true);
    const value = dateValue ? dateValue?.toString() : '';
    if (nameField === 'dateStart') {
      const valueSet = dateValue ? dateValue.setHours(0, 0, 0, 0) : '';
      setFormValues((prev: any) => ({
        ...prev,
        [nameField]: new Date(valueSet),
        timeStart: null,
        dateFinish: null,
        timeFinish: null
      }));
      const error: string = Validations(nameField, value, formValidations) || "";
      setFormErrors((prev: any) => ({
        ...prev,
        [nameField]: error
      }));
    } else if (nameField === 'timeStart') {
      setFormValues((prev: any) => ({
        ...prev,
        dateFinish: null,
        timeFinish: null
      }));
      if (formValues.dateStart !== null) {
        const dateStartValue = moment(new Date(formValues.dateStart)).locale('es').utcOffset(-5, true).format('YYYY-MM-DD');
        const currentDateValue = moment(new Date()).locale('es').utcOffset(-5, true).format('YYYY-MM-DD');
        if (moment(value).isValid()) {
          const timeStartValue = moment(new Date(value)).format('HH:mm:ss');
          const dateTimeStart = new Date(dateStartValue + " " + timeStartValue);
          setFormValues((prev: any) => ({
            ...prev,
            [nameField]: dateTimeStart
          }));
          if (dateStartValue > currentDateValue) {
            formValidations.timeStart.minTime = moment(dateStartValue + " 00:00:00").toDate();
          } else {
            const currentTime = moment(new Date()).format('HH:mm:ss');
            formValidations.timeStart.minTime = moment(dateStartValue + " " + currentTime).toDate();
          }
          const error: string = Validations(nameField, dateTimeStart.toISOString(), formValidations) || "";
          setFormErrors((prev: any) => ({
            ...prev,
            [nameField]: error
          }));
        } else {
          setFormErrors((prev: any) => ({
            ...prev,
            [nameField]: "Hora invalida"
          }));
        }
      }
    } else if (nameField === 'dateFinish') {
      const dateFinishValue = moment(new Date(dateValue + "")).locale('es').utcOffset(-5, true).format('YYYY-MM-DD');
      const startDateValue = moment(new Date(formValues.dateStart + "")).locale('es').utcOffset(-5, true).format('YYYY-MM-DD');
      if (dateFinishValue > startDateValue) {
        const valueSet = dateValue ? dateValue.setHours(0, 0, 0, 0) : '';
        setFormValues((prev: any) => ({
          ...prev,
          [nameField]: new Date(valueSet),
          timeFinish: null
        }));
      } else if (dateFinishValue === startDateValue) {
        if (moment(formValues.timeStart).isValid()) {
          setFormValues((prev: any) => ({
            ...prev,
            [nameField]: new Date(formValues.timeStart + ""),
            timeFinish: null
          }));
        } else {
          setFormValues((prev: any) => ({
            ...prev,
            [nameField]: new Date(value),
            timeFinish: null
          }));
        }
      }
      if (formValues.dateStart !== null) {
        formValidations.dateFinish.minDate = moment(formValues.dateStart).toDate();
        const error: string = Validations(nameField, value, formValidations) || "";
        setFormErrors((prev: any) => ({
          ...prev,
          [nameField]: error
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          dateFinish: 'Por favor Ingrese la fecha de inicio'
        }));
      }
    } else if (nameField === 'timeFinish') {
      if (formValues.dateFinish !== null) {
        const dateFinishValue = moment(new Date(formValues.dateFinish)).locale('es').utcOffset(-5, true).format('YYYY-MM-DD');
        if (moment(value).isValid()) {
          const timeFinishValue = moment(new Date(value)).format('HH:mm:ss');
          const dateTimeFinish = new Date(dateFinishValue + " " + timeFinishValue)
          setFormValues((prev: any) => ({
            ...prev,
            [nameField]: dateTimeFinish
          }));
          formValidations.timeFinish.minTime = moment(formValues.dateFinish).toDate();
          const error: string = Validations(nameField, dateTimeFinish.toISOString(), formValidations) || "";
          setFormErrors((prev: any) => ({
            ...prev,
            [nameField]: error
          }));
        } else {
          setFormErrors((prev: any) => ({
            ...prev,
            [nameField]: "Hora invalida"
          }));
        }
      }
    }
  }

  const changeStateModal = (e: any, selectedId: number, state: string) => {
    setStateToChange(state);
    setOpenChangeModal(true);
    setIdSelected(selectedId);
  }

  const closeModalChange = () => {
    setOpenChangeModal(false);
  }

  const changeStateRequest = async () => {
    setOpenChangeModal(false);
    const responseCSNE = await trackPromise(updateEventsNews(
      { id: idSelected, state: stateToChange, modifyUser: userContext.userId }
    ));
    validateModals(responseCSNE)
  }

  const validateModals = async (responseService: Response) => {
    if (responseService.status === 200) {
      setSuccessMessage('Acción exitosa');
      setOpenModalSuccess(true);
      setOpenModalError(false);
    } else {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
      setOpenModalSuccess(false);
    }
  }

  return (
    <>
      {!changeState ?
        <>
          <Grid container item xs={12} lg={10} className="event-news-form">
            <Grid container item xs={12} className="event-form-container1">
              <Grid container item xs={12} className="title-new-event-container">
                <Typography variant="h2" className="title">
                  Información general
                </Typography>
              </Grid>
              <Grid container item xs={12} direction="row" className="event-form-content1">
                <Grid item xs={12} sm={6} className="field-box-margin">
                  <Grid item xs={12} className="checkbox-container">
                    <FormControl component="fieldset" disabled={action === "Edit" ? true : false}>
                      <Typography variant="h5" className="field-box-title">Tipo*</Typography>
                      <RadioGroup row className="radio-container" name="type" value={formValues.type} onChange={handleChange}>
                        <FormControlLabel value="Evento" className="radio-btn" control={<Radio className="color-btn" />} label="Evento" />
                        <FormControlLabel value="Noticia" className="radio-btn" control={<Radio className="color-btn" />} label="Noticia" />
                        <FormControlLabel value="Instructivo" className="radio-btn" control={<Radio className="color-btn" />} label="Instructivo" />
                        <FormControlLabel value="Capacitacion" className="radio-btn" control={<Radio className="color-btn" />} label="Capacitación" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Categoría*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-category"
                    select
                    name="category"
                    label={formValues.category === 0 ? "Seleccione" : ""}
                    value={formValues.category === 0 ? "" : formValues.category}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      categoryArray.length > 0 ?
                        categoryArray.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.category}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} className="field-box-margin">
                  <Grid item xs={12} className="checkbox-container">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.highlight}
                          onChange={handleChange}
                          name="highlight"
                        />}
                      label="Recurso destacado (Si selecciona esta opción la publicación se visualizará al inicio de la lista)"
                      className="checkbox"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} className="event-form-container2">
              <Grid container item xs={12} className="title-new-event-container">
                <Typography variant="h2" className="title">
                  Contenido
                </Typography>
              </Grid>
              <Grid container item xs={12} direction="row" className="event-form-content2">
                <Grid item xs={12} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Título del recurso*</Typography>
                  <TextField
                    fullWidth
                    name="name"
                    margin="none"
                    label={formValues.name === "" ? "Ingrese un título" : ""}
                    value={formValues.name}
                    onChange={handleChange}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Imagen del recurso</Typography>
                  <Typography variant="h5" className="field-box-text">Para adjuntar una fotografía de clic en el botón "Adjuntar archivo" y seleccione la imagen que desea adjuntar. La imagen debe estar en formato jpg, debe tener dimensiones de XX pixeles de ancho y XX pixeles de alto, y tener un peso máximo de XX Mb.</Typography>
                  <Typography style={{ color: 'red', fontSize: '14px', marginTop: '8px' }}>{formErrors.fileError}</Typography>
                </Grid>
                <Grid container>
                  {formValues.files.length > 0 &&
                    formValues.files.map((file: IFile, index) => (
                      <Grid item xs={12} key={index} className="card-image-container">
                        <div className="card-header-container">
                          <Typography variant="h5" className="regular-serif card-title-item">
                            {file.file?.name}
                          </Typography>
                          <IconButton style={{ padding: 0, height: '30px' }} onClick={handleDeleteFile}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <Grid container item xs={12} justify="flex-end" className="card-image-content">
                          <img src={file.previewUrl} alt="imagen noticia" className="image-noticia" />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Grid container item xs={12} justify="flex-start">
                  <div className="upload-button-container">
                    <Button disabled={formValues.files.length > 0} variant="contained" component="label" className="upload-button">
                      <Typography variant="h5" className="regular-serif">
                        Adjuntar archivo
                      </Typography>
                      <div>
                        <input type="file" name="files" onChange={handleChange} accept="image/jpeg" hidden />
                      </div>
                    </Button>
                  </div>
                </Grid>

                <Grid container item xs={12} className="line" />

                <Grid item xs={12} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Resumen del recurso*</Typography>
                  <Typography variant="h5" className="field-box-text">Por favor introduzca en este campo un máximo de XXX caracteres. Este campo de texto se visualizará en la miniatura de la publicación.</Typography>
                  <TextField
                    fullWidth
                    name="shortDescription"
                    placeholder="Resumen"
                    multiline
                    rows={3}
                    variant="outlined"
                    value={formValues.shortDescription || ""}
                    onChange={handleChange}
                    error={!!formErrors.shortDescription}
                    helperText={formErrors.shortDescription}
                    margin="none"
                  />
                </Grid>

                <Grid container item xs={12} className="line" />

                <Grid item xs={12} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Descripción del recurso*</Typography>
                  <Grid item xs={12}>
                    <ReactQuill
                      placeholder="Crea un descripcion detallada en este espacio"
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "100px",
                      }}
                      value={formValues.description}
                      onChange={handleChangeEditor}
                    />
                  </Grid>
                </Grid>

                {formValues.type === "Evento" &&
                  <>
                    <Grid container item xs={12} className="line" />

                    <Grid item xs={12} sm={6} xl={4} className="field-box-margin special-box-right">
                      <Typography variant="h5" className="field-box-title">Fecha de inicio del evento*</Typography>
                      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                        <DatePicker
                          openTo="year"
                          views={['year', 'month', 'date']}
                          value={formValues.dateStart || null}
                          onChange={(value) => handleChangeDate(value, 'dateStart')}
                          renderInput={(params) => (
                            <TextField
                              disabled
                              {...params}
                              fullWidth
                              name="dateStart"
                              margin="none"
                              label={formValues.dateStart !== null ? "" : "Seleccione Fecha dd/mm/aaaa"}
                              error={!!formErrors.dateStart}
                              helperText={formErrors.dateStart}
                              InputLabelProps={{ shrink: false }}
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} xl={6} className="field-box-margin special-box-right">
                      <Typography variant="h5" className="field-box-title">Hora de inicio del evento*</Typography>
                      <div style={{ width: "150px" }}>
                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                          <TimePicker
                            disabled={!!formErrors.dateStart ? true : !moment(formValues.dateStart).isValid() ? true : false}
                            value={formValues.timeStart || null}
                            ampm={true}
                            onChange={(value) => handleChangeDate(value, 'timeStart')}
                            renderInput={(params) => (
                              <TextField
                                disabled
                                {...params}
                                fullWidth
                                name="timeStart"
                                margin="none"
                                label={formValues.timeStart !== null ? "" : "Seleccione"}
                                error={!!formErrors.timeStart}
                                helperText={formErrors.timeStart}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} xl={4} className="field-box-margin special-box-right">
                      <Typography variant="h5" className="field-box-title">Fecha de finalización del evento*</Typography>
                      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                        <DatePicker
                          disabled={!!formErrors.timeStart ? true : !moment(formValues.timeStart).isValid() ? true : false}
                          openTo="year"
                          views={['year', 'month', 'date']}
                          value={formValues.dateFinish || null}
                          onChange={(value) => handleChangeDate(value, 'dateFinish')}
                          renderInput={(params) => (
                            <TextField
                              disabled
                              {...params}
                              fullWidth
                              name="dateFinish"
                              margin="none"
                              label={formValues.dateFinish !== null ? "" : "Seleccione Fecha dd/mm/aaaa"}
                              error={!!formErrors.dateFinish}
                              helperText={formErrors.dateFinish}
                              InputLabelProps={{ shrink: false }}
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} xl={6} className="field-box-margin special-box-right">
                      <Typography variant="h5" className="field-box-title">Hora de finalización del evento*</Typography>
                      <div style={{ width: "150px" }}>
                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                          <TimePicker
                            disabled={!!formErrors.dateFinish ? true : !moment(formValues.dateFinish).isValid() ? true : false}
                            value={formValues.timeFinish || null}
                            ampm={true}
                            onChange={(value) => handleChangeDate(value, 'timeFinish')}
                            renderInput={(params) => (
                              <TextField
                                disabled
                                {...params}
                                fullWidth
                                name="timeFinish"
                                margin="none"
                                label={formValues.timeFinish !== null ? "" : "Seleccione"}
                                error={!!formErrors.timeFinish}
                                helperText={formErrors.timeFinish}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid item xs={12} className="field-box-margin">
                      <Typography variant="h5" className="field-box-title">Lugar del evento*</Typography>
                      <TextField
                        fullWidth
                        name="place"
                        margin="none"
                        label={formValues.place === "" ? "Ingrese lugar del evento" : ""}
                        value={formValues.place}
                        onChange={handleChange}
                        error={!!formErrors.place}
                        helperText={formErrors.place}
                        InputLabelProps={{ shrink: false }}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                }

                <Grid container item xs={12} className="line" />

                <Grid item xs={12} className="checkbox-container">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.publish}
                        onChange={handleChange}
                        name="publish"
                      />}
                    label="Publicar recurso"
                    className="checkbox"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" className="checkbox-container-description">
                    Al activar esta casilla el recurso se publicará inmediatamente y cambiará a estado "Publicado". Si no activa esta casilla el recurso no se publicará y quedará en estado "No publicado"
                  </Typography>
                </Grid>
                {(formValues.type === "Instructivo" || formValues.type === "Capacitacion") &&
                  <>
                    <Grid container item xs={12} className="line" />
                    <Grid item xs={12} className="field-box-margin">
                      <Grid item xs={12} className="checkbox-container">
                        <FormControl component="fieldset">
                          <Typography variant="h5" className="field-box-title">Poblacion*</Typography>
                          <RadioGroup row className="radio-container" name="population" value={formValues.population} onChange={handleChange}>
                            <FormControlLabel value="Egresados" className="radio-btn" control={<Radio className="color-btn" />} label="Egresados" />
                            <FormControlLabel value="Empresas" className="radio-btn" control={<Radio className="color-btn" />} label="Empresas" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                }

              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} lg={2} justify="center" style={{ height: 'fit-content' }}>
            <NotificationBox
              bgColor="#CFF4FC"
              title="Recursos"
              titleColor="#055160"
              description="En esta sección puede crear un recurso y publicarlo. Todos los campos son obligatorios a excepción de la imagen. También puede escoger si desea publicar el recurso."
              descColor="#055160"
              icon={<InfoIcon style={{ color: '#055160' }} fontSize="large" />}
            />
          </Grid>

          <Grid container item xs={12} lg={10} className="buttons-container-news">
            <Grid container item xs={12} sm={6} lg={6} className="left">
              <Button variant="contained" className="cancel-button" startIcon={<ChevronLeftIcon />} onClick={cancelAction}>
                <Typography variant="h6" className="regular-serif">
                  Salir
                </Typography>
              </Button>
              <Button variant="contained" className={action === "Edit" ? "delete-button" : "no-button"} onClick={(e) => { changeStateModal(e, formValues.id, 'Inactivo') }}>
                <Typography variant="h6" className="regular-serif">
                  Eliminar publicación
                </Typography>
              </Button>
            </Grid>
            <Grid container item xs={12} sm={6} lg={6} className="right">
              <Button disabled={formValues.type === ""} className="preview-button" variant="contained" size="small" onClick={(e) => previewButton(e, formValues)}>
                <Typography variant="h5" className="regular-serif">
                  Previsualizar
                </Typography>
              </Button>
              <Button disabled={!isValid} onClick={handleSave} className="confirm-button" variant="contained" size="small">
                <Typography variant="h5" className="regular-serif">
                  Guardar
                </Typography>
              </Button>
            </Grid>
            <ModalOffersAction
              open={openModalSave}
              handleClose={closeModalSave}
              handleContinue={requestSave}
              iconModal={<InfoIcon className="big-info-icon" />}
              title={`¿Desea guardar ${formValues.publish ? 'y publicar' : 'sin publicar'}?`}
              headerdescription={formValues.publish ? "Está información se publicará inmediatamente." : "Esta información se guardará sin publicarse. Usted podrá ingresar nuevamente y ediatr para publicar esta información."}
              description=""
              backBtnText="Regresar"
              colorBtnBack=""
              continueBtnText={formValues.publish ? "Guardar y publicar" : "Guardar sin publicar"}
              colorBtnContinue="#14A5DC"
              valid={true}
            />
            <ModalOffersAction
              open={openChangeModal}
              handleClose={closeModalChange}
              handleContinue={changeStateRequest}
              iconModal={<WarningIcon className="big-warning-icon" />}
              title="Esta seguro de eliminar la publicación"
              headerdescription="Esta intentando eliminar una publicación, una vez eliminada la única forma de restaurarla será creando una nueva"
              description=""
              backBtnText="Regresar"
              colorBtnBack=""
              continueBtnText="Eliminar"
              colorBtnContinue="#f44336"
              valid={true}
            />
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress} />
          </Grid>
        </>
        :
        <Redirect to="/admin/dashboard/noticias" />
      }
    </>
  )
}

export default NewsEventsForm
