export class IRegisterTraining {
    type: string;
    category: number;
    highlight: boolean;
    fileType: string;
    name: string;
    shortDescription: string;
    description: string;
    publish: boolean;
    population: string;
    constructor(def:any){
      this.type = def.type || "";
      this.category = def.category || 0;
      this.highlight = def.highlight || false;
      this.fileType = def.fileType || "";
      this.name = def.name || "";
      this.shortDescription = def.shortDescription || "";
      this.description = def.description || "";
      this.publish = def.publish || false;
      this.population = def.population || "";
    }
}