import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { IGetNewEvent } from '../../../interfaces/IGetNewEvent';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import './NewsEventsTable.scss';
import { permissionAdminModifyNewEvent } from '../../../utils/PermissionsVariables';
import { containsAny } from '../../../helpers/sharedFunctions';
import UserContext from '../../../utils/UserContext';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface NewsEventsTableProps{
    data: Array<IGetNewEvent>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage:any;
    handleUpdate: any;
    categoryArr: Array<IGeneralParameter>;
    changeStateModal:any
}

const NewsEventsTable: React.FC<NewsEventsTableProps> = ({data, pageSize, onChanhePageSize,  initPage, setInitPage, handleUpdate, categoryArr, changeStateModal}) => {
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [NewSelected, setNewSelected] = useState<IGetNewEvent>(new IGetNewEvent());

    const columnsAnnouncement: GridColDef[] = [
        { field: 'name', headerName: 'Nombre', width: 200, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Nombre'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell"  id="name-cell2">{params.value}</span>
        )} },
        { field: 'shortDescription', headerName: 'Resumen', width: 300, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Resumen'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              return(
                  <span className="name-cell" id="shortDescription-cell">{params.value}</span>
                )
            }
        },
        { field: 'state', headerName: 'Estado', width: 150, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
                return(
                  <span className="name-cell"  id="shortDescription-cell">{params.value}</span>
                )
            }
        },
        { field: 'category', headerName: 'Categoría', width: 150, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Categoría'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
                if(categoryArr.length>0){
                const catObj = categoryArr.find(cat => cat.parameter_code === params.value);
                if(catObj){
                    return(
                        <span className="curriculum-cell"  id="shortDescription-cell">{catObj.name}</span>
                    )
                }
              }
              return(
                <span></span>
              )
            }
        },
        { field: 'type', headerName: 'Tipo', width: 150, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Tipo'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Noticia' && <div className="verified-state state-button-cell">Noticia</div>}
                {params.value === 'Evento' && <div className="rejected-state state-button-cell">Evento</div>}
                {params.value === 'Instructivo' && <div className="active-state state-button-cell">Instructivo</div>}
                {params.value === 'Capacitacion' && <div className="null-state state-button-cell">Capacitación</div>}

            </strong>
        ) },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={(e)=>{handleCloseMenu();handleUpdate('See', NewSelected)}}>
                        Ver
                    </MenuItem>
                    {containsAny(permissionAdminModifyNewEvent,userContext.userPermissions) &&
                        <MenuItem  onClick={(e)=>{handleCloseMenu(); handleUpdate('Edit', NewSelected) }}>
                            Editar
                        </MenuItem>
                    }
                    {
                        <MenuItem  onClick={(e)=>{handleCloseMenu();changeStateModal(e, NewSelected.id, 'Inactivo')}}>
                            Eliminar publicación
                        </MenuItem>
                    }
                    {
                        <MenuItem  onClick={(e)=>{handleCloseMenu(); changeStateModal(e, NewSelected.id, 'Finalizado')}}>
                            Cambiar estado a Finalizado
                        </MenuItem>
                    }
                </Menu>
            </>
        ) },
    ];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const user: IGetNewEvent = Object.assign(params.row);
        setNewSelected(user)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setInitPage(1)
    }


    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid rowHeight={100} autoHeight className="data-grid-list" disableColumnMenu rows={data} columns={columnsAnnouncement} pageSize={pageSize} pagination  density="compact" disableSelectionOnClick  onPageChange={(params:GridPageChangeParams)=>handlePage(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
            </Grid>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map(item=>(
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default NewsEventsTable;