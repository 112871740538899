import React, { useState } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Alert from '@material-ui/lab/Alert';
import Typography from "@material-ui/core/Typography";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PublicIcon from "@material-ui/icons/Public";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { IGetOffer } from "../../../interfaces/IGetOffer";
import UserContext from "../../../utils/UserContext";
import { containsAny } from "../../../helpers/sharedFunctions";

import "./ShowOfferDetail.scss";


interface ShowOfferDetailProps {
  alreadyApplied?: boolean;
  dataOffer: IGetOffer;
  department: string;
  city: string;
  companyName: string;
  urlCompany: string;
}

const ShowOfferDetail: React.FC<ShowOfferDetailProps> = ({ alreadyApplied, dataOffer, department, city, companyName, urlCompany }) => {
  const userContext = React.useContext(UserContext);

  return (
    <>
      <Grid container item xs={12} className="title-container-box">
        <Typography variant="h1" className="bold-serif">
          <FindInPageIcon className="find-page-icon" />
          Detalle de la oferta
        </Typography>
      </Grid>
      {alreadyApplied &&
        <Grid container item xs={12}>
          <Alert severity="info" style={{ width: '100%', margin: "8px 0" }}>
            <span className="alert-simple-title">Usted ya ha aplicado para esta oferta laboral </span>
          </Alert>
        </Grid>
      }
      <Grid container item className="summary-container">
        <Grid container item xs={12} md={9} className="left-side">
          <Grid item xs={12}>
            {containsAny([1, 2, 3, 5], [userContext.roleSelected]) &&
              dataOffer.nameCompanyVisible &&
              <Grid container item xs={12} className="publish-offer-box">
                <BusinessCenterIcon className="icons" />
                <Typography variant="h5" className="regular-serif text-decoration">
                  {companyName}
                </Typography>
              </Grid>
            }
            {department && department.length > 0 &&
              <Grid container item xs={12} className="publish-offer-box">
                <Grid container>
                  <LocationOnIcon className="icons" />
                  <Typography variant="h4" className="bold-serif">
                    Ubicación de la oferta:
                  </Typography>
                  <Grid item xs={12}>
                    <Typography variant="h5" className="regular-serif ">{department} {city ? `- ${city}` : ''}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid item xs={12}>
            {containsAny([1, 2, 3, 5], [userContext.roleSelected]) &&
              urlCompany.length > 0 &&
              <Grid container item className="publish-offer-box">
                <PublicIcon className="icons" />
                <Typography variant="h5" className="regular-serif text-decoration link-responsive">
                  {urlCompany}
                </Typography>
              </Grid>
            }
            <Grid item className="publish-offer-box">
              <Grid container>
                <CalendarTodayIcon className="icons" />
                <Typography variant="h5" className="title-detail">
                  Fecha de publicación:
                </Typography>
                <Grid item xs={12}>
                  <Typography variant="h4" className="regular-serif">
                    {moment(new Date(dataOffer.jobOfferStartDate)).locale('es').format("dddd, DD MMMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} className="right-side">
          <Grid item className="close-offer-text">

            <Typography variant="h4" className="bold-serif">
              Fecha de cierre de la oferta:
            </Typography>
            <div className="close-offer-details">
              <CalendarTodayIcon className="calendar-icon" />
              <Typography variant="h4" className="regular-serif">
                {moment(new Date(dataOffer.jobOfferEndDate)).locale('es').format("dddd, DD MMMM YYYY")}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowOfferDetail;
