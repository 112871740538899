const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

export const getlistFilterGraduates = async(userToken: string, filter?: object)=>{
    try {
        const response = await fetch(
            `${baseUrl}/graduate/list`,
            {
                method: 'POST',
                body: JSON.stringify(filter),
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getlistFilterCompanies = async(userToken: string, filter?: object)=>{
    try {
        const response = await fetch(
            `${baseUrl}/company/list`,
            {
                method: 'POST',
                headers: {
                    "sie_token": userToken
                },
                body: JSON.stringify(filter)
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getUserlDAP = async (username: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/security/users/LDAP/${username}`,
            {
                method: 'GET',
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const registerUserSIE = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/security/users/register`,
            {
                method: 'POST',
                headers:{
                'sie_token': token
                },
                body: JSON.stringify(bodyRequest),
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getUsersWithRoles = async (userToken: string, filter?: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/security/users/listRoles`,
            {
                method: 'POST',
                body: JSON.stringify(filter),
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const changestatusUser = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/security/users/state`,
            {
                method: 'POST',
                headers:{
                'sie_token': token
                },
                body: JSON.stringify(bodyRequest),
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getlistRoles = async () => {
    try {
        const response = await fetch(
            `${baseUrl}/security/roles/list`,
            {
                method: 'GET'
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getListPermissions = async () => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/security/permissions/list`,
            {
                method: 'GET',
                headers:{
                'sie_token': token
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getListPermissionsByRole = async () => {

    try {
        const response = await fetch(
            `${baseUrl}/security/roles/listPermissions`,
            {
                method: 'GET',
           
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const associatePermissionRole = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/security/permissions/activate`,
            {
                method: 'POST',
                headers:{
                'sie_token': token
                },
                body: JSON.stringify(bodyRequest),
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const associateRoles = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/security/roles/associate`,
            {
                method: 'POST',
                headers:{
                'sie_token': token
                },
                body: JSON.stringify(bodyRequest),
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

// mas message service
export const sendMassiveEmail = async (userToken: string, bodyRequest: object, filterValues: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/notification/send-masive`,
            {
                method: 'POST',
                body: JSON.stringify({ 
                    ...bodyRequest, // Mantener todos los datos previos
                    textFilters: filterValues
                }),
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const listCampusCurriculum = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/security/users/listCampusSchoolCurriculumUser`,
            {
                method: 'POST',
                headers:{
                    "sie_token": token
                },
                body: JSON.stringify(bodyRequest),
            }
        );
        return response;
    } catch (error) {
        return error
    }
}
