import React, { useEffect, useState } from "react";
import moment from "moment";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Redirect, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ShowOfferDetail from "../../../components/LaboralOffers/ShowOfferDetail";
import DescriptionOfferBox from "../../../components/LaboralOffers/DescriptionOfferBox";
import SummaryDescriptionOffer from "../../../components/LaboralOffers/SummaryDescriptionOffer";
import { IGetOffer } from "../../../interfaces/IGetOffer";
import { getlistOffers, getOffersByGraduate } from '../../../services/LaboralOfferService';
import ButtonBackListOffers from "../../../components/LaboralOffers/ButtonBackListOffers";
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { isLogin } from "../../../services/AuthService";
import ModalOffersAction from "../../../components/LaboralOffers/ModalOffersAction";
import { IGetPostulation } from "../../../interfaces/IGetPostulation";
import UserContext from "../../../utils/UserContext";
import { getCompanyInfo } from "../../../services/CompanyService";
import SuccessModal from "../../../components/ShareComponents/SuccessModal";
import ErrorModal from "../../../components/ShareComponents/ErrorModal";
import LoadingLockPage from "../../../components/ShareComponents/LoadingLockPage";
import { getUserPersonalInfo } from "../../../services/GraduateService";
import { IPersonalInfoCV } from '../../../interfaces/IPersonalInfoCV';
import { containsAny, sortByKey } from "../../../helpers/sharedFunctions";
import { getCITY, getDEPARTAMENT, getLABORAL_SECTOR, getOccupationType, getTypeOfContract, getSALARY_ASPIRATION } from "../../../services/ParameterService";
import { createPostulation, listPostulations, statePostulationService } from "../../../services/PostulationService";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";

import "./OfferDetailView.scss";


const OfferDetailView: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer') + "" : "";
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [preUrl, setPrevUrl] = useState<string>('');
  const [dataOffer, setDataOffer] = useState<any>({});
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [departmentSelected, setDepartmentSelected] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [urlCompany, setUrlCompany] = useState<string>('');
  const [typePosition, setTypePosition] = useState<string>('');
  const [laboralSector, setLaboralSector] = useState<string>('');
  const [contract, setContract] = useState<string>('');
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [openModalCVUpdate, setOpenModalCVUpdate] = useState<boolean>(false);
  const [CVUpdated, setCVUpdated] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [urlGo, setUrlGo] = useState<string>("");
  const [modalApply, setModalApply] = useState(false);
  const [modalRetry, setModalRetry] = useState(false);
  const [modalSalaryAsp, setModalSalaryAsp] = useState(false);
  const [postulationId, setPostulationId] = useState(0)
  let params: any = useParams();
  const isLogg = isLogin();
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [secondSuccessMessage, setSecondSuccessMessage] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
  const [salaryAspArr, setSalaryAspArr] = useState<Array<IGeneralParameter>>([]);
  const [salarySelected, setSalarySelected] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem('offerAnonimous');
    const urlPath = window.location.href;
    if (urlPath.includes('empresa')) {
      setPrevUrl('/empresa/dashboard/ofertas');
    } else if (urlPath.includes('egresado')) {
      setPrevUrl('/egresado/dashboard/ofertas')
    } else {
      setPrevUrl('/ofertas')
    }
    const getInitialData = async () => {
      if (params.id) {
        let dataCity: Array<IGeneralParameter> = await getCITY();
        const responseOffer: Response = await getlistOffers({ id: params.id });
        if (responseOffer.status === 200) {
          const resultOffer: IGetOffer = (await responseOffer.json()).jobOffers[0];
          if (resultOffer) {
            setDataOffer(resultOffer);
            let responseDepartment: Array<IGeneralParameter> = await getDEPARTAMENT();
            const depSelected = responseDepartment.find(item => item.parameter_code === resultOffer.department);
            setDepartmentSelected(depSelected?.name || '');
            const citySelected = dataCity.find(item => item.parameter_code === resultOffer.city);
            setCity(citySelected?.name || '');
            if (urlPath.includes('empresa')) {
              const responseCompany = await getCompanyInfo(userToken, resultOffer.company);
              if (responseCompany) {
                const resultCompany = await responseCompany.json();
                //setCompanyName(resultCompany.company.name);
                //setUrlCompany(resultCompany.company.website);
              }
            }
            let responsePostion: Array<IGeneralParameter> = await getOccupationType();
            const positionSelected = responsePostion.find(item => item.parameter_code === resultOffer.typePosition);
            setTypePosition(positionSelected?.name ? positionSelected.name : '');
            let respLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
            const laboralSelected = respLaboralSector.find(item => item.parameter_code === resultOffer.laboralSector);
            setLaboralSector(laboralSelected?.name ? laboralSelected?.name : '');
            let respTypeContrat: Array<IGeneralParameter> = await getTypeOfContract();
            const contractSelected = respTypeContrat.find(item => item.parameter_code === resultOffer.typeContract);
            setContract(contractSelected?.name ? contractSelected?.name : '');
            let salaryAspArray: Array<IGeneralParameter> = await getSALARY_ASPIRATION();
            salaryAspArray = sortByKey(salaryAspArray, "parameter_code")
            setSalaryAspArr(salaryAspArray);

            if (userContext.roleSelected === 1) {
              setShowButtons(true);
              if (userContext.userId && userContext.userId.length > 0) {
                const responseOBG = await getOffersByGraduate(parseInt(userContext.userId));
                if (responseOBG.status === 200) {
                  const result = (await responseOBG.json()).jobOffers;
                  if (result.length > 0) {
                    const found = result.find((offer: IGetOffer) => offer.id === parseInt(params.id));
                    if (found) {
                      setAlreadyApplied(true)
                      const responseLP = await listPostulations({ graduateId: userContext.userId });
                      // console.dir(responseLP)
                      const postulations = (await responseLP.json()).postulates;
                      if (postulations.length > 0) {
                        const postulationFound = postulations.find((post: IGetPostulation) => post.offerId === parseInt(params.id));
                        if (postulationFound) {
                          setPostulationId(postulationFound.postulationId);
                        }
                      }
                    }
                  } else {
                    setAlreadyApplied(false)
                  }
                }
              } else {
                setAlreadyApplied(false)
              }
              if (userContext.userId && userContext.userId.length > 0) {
                const responsePerfonalCV = await getUserPersonalInfo(userToken, parseInt(userContext.userId));
                if (responsePerfonalCV.status === 200) {
                  const resultUser: IPersonalInfoCV = await responsePerfonalCV.json();
                  if (resultUser.personalIdentificationData.updatedAt) {
                    const updateCV = moment(new Date(resultUser.personalIdentificationData.updatedAt + "")).format("YYYY-MM-DD");
                    // const updateCV = moment(new Date("2018-10-12")).format("YYYY-MM-DD")
                    const dateToCheck = moment(new Date()).subtract(6, 'months').format("YYYY-MM-DD");
                    if (updateCV > dateToCheck) {
                      setCVUpdated(true)
                    } else {
                      setCVUpdated(false)
                    }
                  } else {
                    setCVUpdated(false)
                  }
                }
              }
            } else if (containsAny([2, 3], [userContext.roleSelected])) {
              setShowButtons(false)
              setAlreadyApplied(false)
            } else if (userContext.roleSelected === 0) {
              setShowButtons(true);
              setAlreadyApplied(false)
            }
          }
        }
      }
      setInitLoad(false);
    }
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleChangeSalary = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSalarySelected(parseInt(value));
  }

  const handleApply = () => {
    if (!isLogg) {
      setOpenLoginModal(true);
      sessionStorage.setItem('offerAnonimous', params.id);
    }
    else {
      if (!CVUpdated && !alreadyApplied) {
        setOpenModalCVUpdate(true);
      } else {
        if (!alreadyApplied) {
          setModalApply(true)
        } else {
          setModalRetry(true)
        }
      }
    }
  }

  const handleApplyBtn = async () => {
    if (!alreadyApplied) {
      setModalApply(false);
      if (userContext.userId && userContext.userId.length > 0) {
        const responseCP = await trackPromise(createPostulation(
          { offerId: params.id, graduateId: userContext.userId, 
            salaryAspiration: salarySelected,modifyUser: userContext.userId }
        ));
        if (responseCP.status === 200) {
          let data = await responseCP.json()
          setSuccessMessage(`Su aplicacion en esta oferta ha sido exitosa`)
          setSecondSuccessMessage(`${data.state === 'Preseleccionado' ? "Usted ha sido preseleccionado" : ""}`)
          setOpenModalSuccess(true);
          setOpenModalError(false);
        } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
        }
      }
    } else {
      if (postulationId > 0) {
        setModalRetry(false);
        const responseSP = await trackPromise(statePostulationService(
          { postulationId: postulationId, state: "Retirado", modifyUser: userContext.userId }
        ));
        if (responseSP.status === 200) {
          setSuccessMessage('Estimado egresado(a) se ha retirado satisfactoriamente de esta oferta laboral')
          setOpenModalSuccess(true);
          setOpenModalError(false);
        } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
        }
      }
    }
  }

  const handleCloseModalSuccess = () => {
    setOpenModalSuccess(false);
    setUrlGo("/egresado/dashboard/");
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
    window.location.reload();
  };

  const handleUpdate = () => {
    setUrlGo("/egresado/dashboard/curriculum");
    setOpenModalCVUpdate(false);
  }

  const closeModalLogin = () => {
    setOpenLoginModal(false);
    sessionStorage.removeItem('offerAnonimous');
  }

  const closeModalCVUpdated = () => {
    setOpenModalCVUpdate(false);
  }

  const closeModalApply = () => {
    setModalApply(false);
  }

  const closeModalRetry = () => {
    setModalRetry(false);
  }

  return (
    <>
      {urlGo.length > 0 ?
        <Redirect to={urlGo} />
        :
        <>
          <Grid container item xs={12} className="back-offers-list-section">
            <ButtonBackListOffers url={preUrl} text="Regresar al listado de ofertas" />
          </Grid>
          <Grid container item xs={12} className="job-offers-detail-section">
            <Grid container className="main-container">
              <Grid container item xs={12}>
                <ShowOfferDetail alreadyApplied={alreadyApplied} dataOffer={dataOffer} department={departmentSelected} city={city} companyName={containsAny([1, 2, 3], [userContext.roleSelected]) ? companyName : ''} urlCompany={containsAny([1, 2, 3], [userContext.roleSelected]) ? urlCompany : ''} />
              </Grid>
              <Grid container item xs={12}>
                <Grid container item xs={12} md={8}>
                  <Grid container className="description-container">
                    <DescriptionOfferBox dataOffer={dataOffer} typePosition={typePosition} laboralSector={laboralSector} contract={contract} showButtons={showButtons} handleApply={handleApply} userApply={alreadyApplied} />
                    
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={4}>
                  <SummaryDescriptionOffer dataOffer={dataOffer} department={departmentSelected} city={city} companyName={containsAny([1, 2, 3], [userContext.roleSelected]) ? companyName : ''} typePosition={typePosition} laboralSector={laboralSector} contract={contract} showButtons={showButtons} handleApply={handleApply} userApply={alreadyApplied} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ModalOffersAction
            open={openLoginModal}
            handleClose={closeModalLogin}
            iconModal={<InfoIcon className="big-info-icon" />}
            title="Respetado egresado le invitamos a iniciar sesión para poder aplicar"
            headerdescription=""
            description=""
            backBtnText="Regresar"
            colorBtnBack=""
            continueBtnText="Continuar"
            colorBtnContinue="#14A5DC"
            linkContinue="/login"
            valid={true}
          />
          <ModalOffersAction
            open={openModalCVUpdate}
            handleClose={closeModalCVUpdated}
            iconModal={<InfoIcon className="big-info-icon" />}
            handleContinue={handleUpdate}
            title="Actualice su Hoja de vida"
            headerdescription=""
            description="Respetado egresado le invitamos a actualizar su hoja de vida, para poder aplicar a las ofertas"
            backBtnText="Regresar"
            colorBtnBack=""
            continueBtnText="Actualizar"
            colorBtnContinue="#14A5DC"
            valid={true}
          />
          <ModalOffersAction
            open={modalApply}
            handleClose={closeModalApply}
            handleContinue={handleApplyBtn}
            iconModal={<WarningIcon className="big-warning-icon"/>}
            title="¿Está seguro de Aplicar a la oferta?"
            headerdescription="Por favor ingrese su aspiracion salarial para esta oferta"
            description=""
            backBtnText="Regresar"
            colorBtnBack=""
            continueBtnText="Si, Aplicar"
            colorBtnContinue="#f44336"
            valid={true}
          >
            
            {salaryAspArr.length > 0 &&
              <TextField
                fullWidth
                id="outlined-select-salaryAspiration"
                select
                name="salaryAspiration"
                label={salarySelected === 0 ? "Seleccione" : ""}
                value={salarySelected === 0 ? "" : salarySelected}
                onChange={handleChangeSalary}
                InputLabelProps={{ shrink: false }}
                margin="none"
                variant="outlined"
              >
                {
                  salaryAspArr && salaryAspArr.length > 0 &&
                  <MenuItem value={0}><em>Ninguno</em></MenuItem>
                }
                {
                  salaryAspArr.length > 0 ?
                    salaryAspArr.map((option) => (

                      <MenuItem key={option.parameter_code} value={option.parameter_code}>
                        {option.name}
                      </MenuItem>
                    ))
                    :
                    <MenuItem value={salarySelected}></MenuItem>
                }
              </TextField>
            }
          </ModalOffersAction>
          <ModalOffersAction
            open={modalRetry}
            handleClose={closeModalRetry}
            handleContinue={handleApplyBtn}
            iconModal={<WarningIcon className="big-warning-icon" />}
            title=""
            headerdescription=""
            description="¿Está seguro de Retirarse de la oferta?"
            backBtnText="Regresar"
            colorBtnBack=""
            continueBtnText="Si, Retirarme"
            colorBtnContinue="#f44336"
            valid={true}
          />
          <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} secondMsg={secondSuccessMessage}/>
          <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
          <LoadingLockPage load={promiseInProgress} />
          <LoadingLockPage load={initLoad} />
        </>
      }
    </>
  );
};

export default OfferDetailView;
