import React, { useState } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { GridCellParams, GridColDef, GridColParams } from '@material-ui/data-grid';
import ConfirmActionBox from '../../ManagementState/ConfirmActionBox';
import DataTableAction from '../../ManagementState/DataTableAction';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Validations from '../../../helpers/Validations';
import ModalConfirmedAction from '../../ShareComponents/ModalConfirmedAction';
import { OfferStateObject } from '../../../interfaces/OfferStateObject';
import { changeStateLaboralOffer } from '../../../services/ChangeStateService';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { IAnnouncement } from '../../../interfaces/IAnnouncement';
import { OfferStateTosendObj } from '../../../pages/dashboard/admin/CompanyInfoView';
import UserContext from '../../../utils/UserContext';


const fieldsValidation = {
    causal: {
        error: "",
        validate: "textNumber",
        maxLength: 200,
        required: true
    }
};

const initValues = {
    causal: ""
}

const initialErrorCausal = {
    causal: ''
}

interface OffersChangeActionProps {
    offersObject: Array<IAnnouncement>
    offersStateToSend: OfferStateTosendObj;
    backComponent: any;
    cancel?: any;
    active?: boolean;
}

const OffersChangeAction: React.FC<OffersChangeActionProps> = ({ offersObject, offersStateToSend, backComponent, cancel, active }) => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [cancelActive, setCancelActive] = useState<boolean>(false);
    const [formValues, setFormValues] = useState(initValues)
    const [formErrors, setFormErrors] = useState(initialErrorCausal);
    const [stateChanged, setStateChanged] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState('');
    const [openModalError, setOpenModalError] = useState(false);

    const columnsAnnouncementAction: GridColDef[] = [
        {
            field: 'jobTitle', headerName: 'Nombre del cargo en la empresa', width: 300, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Nombre del cargo en la empresa'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell">{params.value}</span>
                )
            }
        },
        {
            field: 'jobOfferStartDate', headerName: 'Fecha de publicación', width: 250, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-break">
                        Fecha de <br /> publicación
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                const valueDate = moment((params.value)?.toString()).format('YYYY/MM/DD');
                return (
                    <span className="date-cell">{valueDate}</span>
                )
            }
        },
        {
            field: 'jobOfferEndDate', headerName: 'Fecha final', width: 250, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-break">
                        Fecha final
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                const valueDate = moment((params.value)?.toString()).format('YYYY/MM/DD');
                return (
                    <span className="date-cell">{valueDate}</span>
                )
            }
        },
        {
            field: 'state', headerName: 'Estado', width: 300, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Estado'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => (
                <strong>
                    {params.value === 'En definición' && <div className="active-state state-applicant-button-cell">En definición</div>}
                    {params.value === 'Convocatoria abierta' && <div className="signed-up-state state-applicant-button-cell">Convocatoria Abierta</div>}
                    {params.value === 'En Selección' && <div className="verified-state state-applicant-button-cell">En Selección</div>}
                    {params.value === 'Finalizado' && <div className="rejected-state state-applicant-button-cell">Finalizado</div>}
                    {params.value === 'Cancelada' && <div className="inactive-state state-applicant-button-cell">Cancelada</div>}
                </strong>
            )
        },
    ];

    const isValid =
        offersStateToSend.offers.length > 0 &&
        (
            offersStateToSend.offers[0].state === "Convocatoria abierta" ||
            (
                offersStateToSend.offers[0].state === "Cancelada" &&
                formValues.causal.length > 0 &&
                !formErrors.causal
            )
        )

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, fieldsValidation) || "";
        setFormErrors(prev => ({
            ...prev,
            [name]: error
        }));
    }

    const cancelClick = () => {
        setCancelActive(true)
        if (cancel != null) {
            cancel();
        }
    };

    const sendChangeState = async () => {
        offersStateToSend.offers.forEach((offer: OfferStateObject) => {
            if (offer.state === 'Convocatoria abierta') {
                offer.causal = ""
            } else if (offer.state === 'Cancelada') {
                offer.causal = formValues.causal
            }
        })
        const offerToChange = offersStateToSend.offers[0];
        const responseCSO = await trackPromise(changeStateLaboralOffer(
            { ...offerToChange, modifyUser: userContext.userId }
        ));
        if (responseCSO.status === 200) {
            setStateChanged(true);
            setServiceError('');
            setOpenModalError(false);
        } else {
            const result = await responseCSO.message;
            setStateChanged(false);
            setServiceError(result)
            setOpenModalError(true);
        }
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
        setCancelActive(true);
    };

    const handleClose = () => {
        if (cancel != null) {
            cancel();
        } else {
            setCancelActive(true);
            setStateChanged(false);
        }
    };

    return (
        <>
            {(active != null ? !active : cancelActive) ?
                backComponent
                :
                <Grid container className="main-container">
                    <div className="graduate-change-action-container">
                        {
                            offersObject.length > 0 &&

                            <Grid container item xs={12}>
                                <Grid container item xs={12}>
                                    <Typography variant="h1" className="change-action-main-title">
                                        Ejecutar acción {offersStateToSend.offers.length > 1 ? " masiva" : " individual"}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Typography variant="h5" className="tab-description">
                                        Antes de ejecutar la acción revise la tabla de datos que se muestra a continuación y la acción seleccionada:
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Typography variant="h4" className="table-results">
                                        ofertas seleccionadas {offersObject.length}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <DataTableAction rows={offersObject} columns={columnsAnnouncementAction} />
                                </Grid>
                            </Grid>
                        }
                        {
                            offersStateToSend.offers.length && offersStateToSend.offers[0].state === "Cancelada" &&
                            <Grid container item xs={12}>
                                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                                    <Typography variant="h4" className="bold-serif-primaryColor">Causal de rechazo*</Typography>
                                    <TextField
                                        fullWidth
                                        name="causal"
                                        margin="none"
                                        label={formValues.causal === "" ? "Ingrese la causal de cancelación" : ""}
                                        value={formValues.causal}
                                        onChange={handleChange}
                                        error={!!formErrors.causal}
                                        helperText={formErrors.causal}
                                        InputLabelProps={{ shrink: false }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid container item xs={12} justify="center">
                            <ConfirmActionBox users={offersStateToSend.offers} state={offersStateToSend.offers[0].state} cancelClick={cancelClick} sendChangeState={sendChangeState} isValid={isValid} />
                        </Grid>
                    </div>
                    <ModalConfirmedAction
                        open={stateChanged}
                        handleClose={handleClose}
                        icon={<CheckCircleOutlineIcon style={{ color: '#7DA52D', fontSize: '50px' }} />}
                        description="Acción ejecutada exitosamente"
                    />
                    <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                </Grid>
            }
            <LoadingLockPage load={promiseInProgress} />
        </>
    )
}

export default OffersChangeAction